import { Globals } from "../components/general/global";
import { IPaymentRequest } from '../models/IPaymentRequest';
import ApiConfig from '../../src/configuration/apiConfiguration';

const verifyPayment = (id: string) => {
    var axios = require('axios');
    var url = ApiConfig.API_CONTROLLER_PAYMENT + ApiConfig.API_METHOD_POST_VERIFY_PAYMENT + "?id=" + id;
    var config = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return axios(config);
}

const makePayment = (paymentRequest: IPaymentRequest) => {
    var axios = require('axios');
    var raw = JSON.stringify(paymentRequest);
    var config = {
        method: 'post',
        url: ApiConfig.API_CONTROLLER_PAYMENT + ApiConfig.API_METHOD_POST_REQUEST_PAYMENT,
        headers: {
            'Content-Type': 'application/json'
        },
        data: raw
    };

    return axios(config);
}

const retrieveStatus = (id: string) => {

    var axios = require('axios');

    var config = {
        method: 'post',
        url: ApiConfig.API_CONTROLLER_PAYMENT + ApiConfig.API_METHOD_POST_PAYMENT_STATUS + "/" + id,
        headers: {
            'Content-Type': 'application/json'
        },
    };

    return axios(config);
}

export default
    {
        makePayment,
        retrieveStatus,
        verifyPayment
    }