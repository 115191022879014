import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { IAPIResult } from "../models/IAPIResult";
import { ITimeSlot } from "../models/ITimeSlot";
import { ITimeSlotBooking } from "../models/ITimeSlotBooking";
import { CalendarService } from "../services/CalendarService";
import { ICalendarService } from "../services/contracts/ICalendarService";


export default function CallInterceptions() {
    const { t, i18n } = useTranslation();
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [showSpinner, setShowSpinner] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>();
    const { relnum, zipcode, language }: any = useParams()
    const [selectedDate, setSelectedDate] = useState();
    const history = useHistory();
    const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>();
    const [filterredTimeSlots, setFilterredTimeSlots] = useState<ITimeSlot[]>();
    const [excludeDates, setExcludeDates] = useState<Date[]>();
    const { handleSubmit } = useForm({

    });

    useEffect(() => {
        handleClosedDays();
        fetchTimeSlots();
    }, [])

    async function fetchTimeSlots() {
        setNextButtonDisabled(true);
        const startDate = moment(selectedDate).format('YYYYMMDD');
        const endDate = moment(selectedDate).add(1, 'M').format('YYYYMMDD')

        const calendarService: ICalendarService = new CalendarService();
        const result: IAPIResult = await calendarService.search(startDate, endDate, language, zipcode);


        if (result.error != null)
            history.push("/error");

        let slots = Object.assign([], result.payload);
        setTimeSlots(slots);
        handleFilterredTimeSlots(startDate);
    }

    const handleCalendarChange = (date) => {
        setSelectedDate(date);
        handleFilterredTimeSlots(date);

    }

    const handleFilterredTimeSlots = (date) => {
        const formattedDate = moment(date).format('YYYYMMDD');

        if (timeSlots != undefined) {
            setFilterredTimeSlots(timeSlots.filter(t => t.date == formattedDate.toString()));
        }
        console.log(filterredTimeSlots);
    }

    const handleClosedDays = () => {
        if (zipcode.length == 4) {
            retrieveClosedDays(zipcode);
        }
    }

    async function retrieveClosedDays(zipCode: string) {
        if (zipCode.length == 4) {
            setShowSpinner(true);
            const calendarService: ICalendarService = new CalendarService();
            const result: IAPIResult = await calendarService.getClosedDays(
                moment(selectedDate).year(),
                moment(selectedDate).month() + 1, language, zipCode);

            if (result.error == null) {
                let tmpExcludeDates = Object.assign([], excludeDates);

                for (var val of result.payload) {
                    const date = new Date(val.toString());
                    tmpExcludeDates.push(date);
                }

                setExcludeDates(tmpExcludeDates);
                setShowSpinner(false);
            }
            else {
                history.push("/error");
            }
        }
    }

    const onSubmit = () => {
    };

    const makeBooking = async () => {
        setShowProcessing(true);

        if (zipcode != "0000") {
            setShowSpinner(true);
            let timeSlotBooking = {} as ITimeSlotBooking;
            timeSlotBooking.timeslotID = selectedTimeSlot;
            timeSlotBooking.relationNumber = relnum;

            const calendarService: ICalendarService = new CalendarService();
            const result: IAPIResult = await calendarService.makeBookingWithRealNum(timeSlotBooking);

            if (result.error != null)
                history.push("/error");

            setShowSpinner(false);
        }
    }

    const handleSelectedTimeSlot = (index, id) => {
        setActiveIndex(index);
        setSelectedTimeSlot(id);
        setNextButtonDisabled(false);
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} >
                <div hidden={showProcessing}>
                    <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                        <img src="/loader.svg" alt="" />
                    </div>
                    <div hidden={showSpinner}>
                        <Row>
                            <Col xs={{ offset: 3, span: 3 }} >
                                <p> <b>{t('PICK_A_DATE')}</b> </p>
                                <DatePicker inline
                                    dateFormat="dd/MM/yyyy"
                                    selected={selectedDate}
                                    excludeDates={excludeDates}
                                    adjustDateOnChange
                                    minDate={moment().toDate()}
                                    onChange={date => handleCalendarChange(date)} />
                            </Col>
                            <Col xs={{ span: 3, offset: 1 }} >
                                <p> <b>{t('PICK_AN_HOUR')}</b>  </p>
                                <div>
                                    {timeSlots?.length == 0 || timeSlots === undefined ? t('PICK_AN_HOUR_NO_VALUES') : ""}
                                    <ul className="form-timeslots__list">
                                        {filterredTimeSlots != null &&
                                            filterredTimeSlots.map((timeSlot: ITimeSlot, index) => (
                                                <li key={timeSlot.id} className="form-timeslots__list__item">

                                                    <label
                                                        id={timeSlot.id}
                                                        onClick={() => handleSelectedTimeSlot(index, timeSlot.id)}
                                                        className={activeIndex === index ? "form-timeslots__list__item_selected" : ""}>
                                                        {timeSlot.startHour} - {timeSlot.endHour}
                                                    </label></li>
                                            ))}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center", marginTop: 50 }} >
                            <Col xs={{ span: 4, offset: 4 }}>
                                <Button disabled={nextButtonDisabled}
                                    className="btn-block btn btn-success CDA_button_green CDA_button text-white" onClick={() => { makeBooking(); setShowProcessing(true); }}>{t('FORM_BUTTON_CONFIRM')} </Button>
                            </Col>
                        </Row></div>
                </div>
                <div hidden={!showProcessing}>
                    <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                        <img src="/loader.svg" alt="" />
                    </div>
                    <div hidden={showSpinner}>
                        <Row className="confirmation_booking_message" md={{ cols: 12 }}>
                            <Col >
                                <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: "#92BB4A" }} />
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 20 }} className="confirmation_booking_message" md={{ cols: 12 }}>
                            <Col><p>{t('BOOKING_CONFIRMED')}</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </form >
        </div >
    );
}