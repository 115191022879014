import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export default function TermsAndConditions() {
    const { t } = useTranslation();
    return (
        <div className="content">
            <Row className="justify-content-md-center" md={{ cols: 12 }}>
                <Col>
                    <h1 className="title">
                        {t('FOOTER_TERMS_AND_CONDITIONS')}
                        <span className="title__dots">
                            <span className="title_dot title__dots__dot--green"></span>
                            <span className="title_dot title__dots__dot--blue"></span>
                            <span className="title_dot title__dots__dot--red"></span>
                        </span>
                    </h1>
                </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
                <Col xs={{ offset: 1 }}>
                    <span className="CDA_sub_title" >
                        {t('FOOTER_TERMS_AND_CONDITIONS')}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1 }}>
                    <p>
                        {t('TERMS_AND_CONDITIONS_TEXT')}
                    </p>
                </Col>
            </Row>
        </div>
    )
}