import React, { useState, useEffect } from "react";
import { Row, Col, Form, Alert, Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PrivacyCondition = (props) => {

    const { t, i18n } = useTranslation();

    return (
        <>
            {
                i18n.language == "nl"
                    ? <Form.Label>{t('FORM_I_AGREE_TERMS_AND_CONDITIONS_PART_1')}<a target="_blank" href="https://cda.be/wp-content/uploads/2022/07/privacy-beleid-cda-nl.pdf">{t('FORM_I_AGREE_TERMS_AND_CONDITIONS_PART_2')}</a></Form.Label>
                    : < Form.Label > {t('FORM_I_AGREE_TERMS_AND_CONDITIONS_PART_1')} <a target="_blank" href="https://cda.be/wp-content/uploads/2022/07/privacy-beleid-cda-fr.pdf" > {t('FORM_I_AGREE_TERMS_AND_CONDITIONS_PART_2')}</a ></Form.Label >}

        </>
    );
}


export default PrivacyCondition;
