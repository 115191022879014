import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from 'react-i18next';
import MyIframe from './myIframe';
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import i18n from "../../i18n";

export default function CarInsurance() {

    const iframeUrl = "https://www.coronadirect.be/nl/cda/autoverzekering/bereken-je-prijs?lang=";
    const { t } = useTranslation();
    const { lang }: any = useParams()

    useEffect(() => {
        if (lang)
            i18n.changeLanguage(lang.toLowerCase());
        else
            i18n.changeLanguage("nl");

    }, [])

    return (
        <div>
            <Row className="justify-content-md-center " md={{ cols: 12 }}>
                <Col>
                    <MyIframe link={iframeUrl + lang} />
                </Col>
            </Row>
        </div >
    )
}