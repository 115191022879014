import React from "react";

const CurrencyFormatter = (props) => {

    const format = (input) => {

        ///^(\d+(?:[\.\,]\d{2})?)$/
        // return "€ " + parseFloat(input).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return "€ " + Number(parseFloat(input).toFixed(2)).toLocaleString('nl', {
            minimumFractionDigits: 2
        });
    }

    return (
        <span><b>{format(props.data)}</b></span>
    );
}

export default CurrencyFormatter;



