import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import CurrencyFormatter from '../general/CurrencyFormatter';
import { useTranslation } from 'react-i18next';

const CalculationSummaryPaymentSelection = (props) => {

    const { t } = useTranslation();

    return (
        <div>
            <Row>
                <Col md={{ offset: 1, span: 9 }} xs={11} >
                    <label>{t('renting_insurcance:INSURRED_CAPITAL')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "right" }}>
                    <label> <CurrencyFormatter data={props.calulationResult.totalCapital} /></label>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 9 }} xs={11} >
                    <label>{t('renting_insurcance:INSURRED_HOUSEHOLD')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "right" }}>
                    <label> <CurrencyFormatter data={props.calulationResult.totalHouseHold} /></label>
                </Col>
            </Row>
            {props.calulationResult.promoActive ?
                <Row style={{ marginBottom: 20, marginTop: 20 }}>
                    <Col md={{ offset: 1, span: 11 }} xs={11} style={{ textAlign: "center" }} className="options_message">
                        {t('renting_insurcance:CONTACT_US_OPTIONS_PART_1')}<a href="/user_question"> {t('renting_insurcance:CONTACT_US_OPTIONS_PART_2')}</a>
                    </Col>
                </Row> : null
            }
            <Row>
                <Col md={{ offset: 1, span: 11 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 9 }} xs={11} >
                    <Form.Label>{t('renting_insurcance:TOTAL_INSURANCE')}</Form.Label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "right" }}>
                    <Form.Label><CurrencyFormatter data={props.calulationResult.finalResultIncTaxes} /></Form.Label>
                </Col>
            </Row>
            {
                props.legalInsuranceAssistanceToPeople === true ?
                    <Row>
                        <Col md={{ offset: 1, span: 9 }} xs={11} >
                            <Form.Label>{t('renting_insurcance:OPTIONS_ASSISTANCE_TO_PEOPLE')}</Form.Label>
                        </Col>
                        <Col xs={2} md={2} style={{ textAlign: "right" }}>
                            <Form.Label> + <CurrencyFormatter data={props.calulationResult.assistanceToPeopleTotal} /></Form.Label>
                        </Col>
                    </Row> : null
            }
            {
                props.legalInsuranceCivilLiability === true ?
                    <Row>
                        <Col md={{ offset: 1, span: 9 }} xs={11} >
                            <Form.Label>{t('renting_insurcance:OPTIONS_CIVIL_LIABILITY')}</Form.Label>
                        </Col>
                        <Col xs={2} md={2} style={{ textAlign: "right" }}>
                            <Form.Label> + <CurrencyFormatter data={props.calulationResult.civilLiabilityTotal} /></Form.Label>
                        </Col>
                    </Row> : null
            }
            {
                props.legalInsuranceAssistanceToPeople === true || props.legalInsuranceCivilLiability === true ?
                    <div>
                        <Row>
                            <Col md={{ offset: 1, span: 11 }} xs={11}>
                                <hr></hr>
                            </Col>
                        </Row>
                        < Row >
                            <Col md={{ offset: 1, span: 9 }} xs={11} >
                                <Form.Label>{t('renting_insurcance:TOTAL')}</Form.Label>
                            </Col>
                            <Col xs={2} md={2} style={{ textAlign: "right" }}>
                                {props.legalInsuranceCivilLiability === true && props.legalInsuranceAssistanceToPeople === false ? <Form.Label> <CurrencyFormatter data={props.calulationResult.finalResultIncTaxes + props.calulationResult.civilLiabilityTotal} /></Form.Label> : null}
                                {props.legalInsuranceCivilLiability === false && props.legalInsuranceAssistanceToPeople === true ? <Form.Label> <CurrencyFormatter data={props.calulationResult.finalResultIncTaxes + props.calulationResult.assistanceToPeopleTotal} /></Form.Label> : null}
                                {props.legalInsuranceCivilLiability === true && props.legalInsuranceAssistanceToPeople === true ? <Form.Label> <CurrencyFormatter data={props.calulationResult.finalResultIncTaxes + props.calulationResult.civilLiabilityTotal + props.calulationResult.assistanceToPeopleTotal} /></Form.Label> : null}
                            </Col>
                        </Row></div> : null
            }
            <Row>
                <Col md={{ offset: 1, span: 11 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row >
                <Col md={{ offset: 5, span: 2 }} xs={11} style={{ textAlign: "center" }}>
                    <label>{t('renting_insurcance:CONTRACT_PRICE')}</label>
                </Col>
                <Col md={3} xs={11} style={{ textAlign: "center" }} >
                    <label>{t('renting_insurcance:CONTRACT_PRICE_FIRST_YEAR')}</label>
                </Col>
            </Row>
            <Row className="lineHeight50">
                <Col md={{ offset: 1, span: 4 }} xs={11} >
                    <label>{t('renting_insurcance:PAYMENT_YEARLY')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label>
                        <CurrencyFormatter data={props.calulationResult.yearPrice} />
                    </label>
                </Col>
                <Col xs={2} md={3} style={{ textAlign: "center" }}>
                    <label>
                        <CurrencyFormatter data={props.calulationResult.yearPriceWithPromo} />
                    </label>
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                    <Button onClick={e => props.handler(1)}
                        style={{ width: 150 }}
                        className="btn-block CDA_button_green CDA_button text-white"  >{t('payments:PAY')}
                    </Button>
                </Col>
            </Row>
            <Row className="lineHeight50">
                <Col md={{ offset: 1, span: 4 }} xs={11} >
                    <label>{t('renting_insurcance:PAYMENT_6_MONTHS')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label>
                        <CurrencyFormatter data={props.calulationResult.halfYearPrice} />
                    </label>
                </Col>
                <Col xs={2} md={3} style={{ textAlign: "center" }}>
                    <label>
                        <CurrencyFormatter data={props.calulationResult.halfYearPriceWithPromo} />
                    </label>
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                    <Button onClick={e => props.handler(4)}
                        style={{ width: 150 }}
                        className="btn-block CDA_button_green CDA_button text-white" >{t('payments:PAY')}
                    </Button>
                </Col>
            </Row>
            <Row className="lineHeight50">
                <Col md={{ offset: 1, span: 4 }} xs={11} >
                    <label>{t('renting_insurcance:PAYMENT_3_MONTHS')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label>
                        <CurrencyFormatter data={props.calulationResult.quarterPrice} />
                    </label>
                </Col>
                <Col xs={2} md={3} style={{ textAlign: "center" }}>
                    <label>
                        <CurrencyFormatter data={props.calulationResult.quarterPriceWithPromo} />
                    </label>
                </Col>
                <Col xs={2} md={1} style={{ textAlign: "right" }}>
                    <Button
                        onClick={e => props.handler(4)}
                        style={{ width: 150 }}
                        className="btn-block CDA_button_green CDA_button text-white" >{t('payments:PAY')}</Button>
                </Col>
            </Row>
        </div >

    )
}

export default CalculationSummaryPaymentSelection

