import React from 'react';

const MyIframe = (props) => {
    let link = "<iframe width='100%' height='750' scrolling='no'   frameborder='no' allow='autoplay'  src='" + props.link + "'></iframe>";


    return (
        <div
            dangerouslySetInnerHTML={{ __html: link ? link : "" }}
        />
    );
};

export default MyIframe;

