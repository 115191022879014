import React, { useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "../i18n";

export default function Intro() {

    const { t } = useTranslation();
    const { lang }: any = useParams()
    let url_CALCULATE_CAR_INSURANCE = "/car_insurance/";
    let url_CALCULATE_CAPITAL = "/calculate_capital/";
    let url_CALCULATE_BOUNTY = "/calculate_bounty/";

    useEffect(() => {
        if (lang)
            i18n.changeLanguage(lang.toLowerCase());
        else
            i18n.changeLanguage("nl");

    }, [])

    return (
        <div style={{ height: 650 }}>
            <Row className="justify-content-md-center " md={{ cols: 12 }}>
                <Col>
                    <h1 className="title">
                        <b>{t('WELCOME')}</b>
                        <span className="title__dots">
                            <span className="title_dot title__dots__dot--green"></span>
                            <span className="title_dot title__dots__dot--blue"></span>
                            <span className="title_dot title__dots__dot--red"></span>
                        </span>
                    </h1>
                </Col>
            </Row>
            <Row className="justify-content-md-center content" md={{ cols: 12 }} style={{ lineHeight: 10 }}>
                <Col md={{ span: 4 }}>
                    <Link to={url_CALCULATE_CAR_INSURANCE + i18n.language} className="call-to-action-container__call-to-action CDA_button_green CDA_button_no_radius">
                        {t('CALCULATE_CAR_INSURANCE')}
                    </Link>
                </Col>
                <Col md={{ span: 4 }}>
                    <Link className="call-to-action-container__call-to-action CDA_button_green CDA_button_no_radius"
                        to={url_CALCULATE_CAPITAL + i18n.language} >
                        {t('CALCULATE_CAPITAL')}
                    </Link>
                </Col>
                <Col md={{ span: 4 }}>
                    <Link className="call-to-action-container__call-to-action  CDA_button_green CDA_button_no_radius"
                        to={url_CALCULATE_BOUNTY + i18n.language}>
                        {t('CALCULATE_BOUNTY')}
                    </Link>
                </Col>
            </Row>
        </div >
    )
}