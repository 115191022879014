import { Globals } from "../components/general/global";
import { ITimeSlot } from "../models/ITimeSlot";
import { ITimeSlotBooking } from "../models/ITimeSlotBooking";
import { ICalendarService } from "./contracts/ICalendarService";
import { IAPIResult } from './../models/IAPIResult';
import ApiConfig from '../../src/configuration/apiConfiguration';

export class CalendarService implements ICalendarService {

    public async search(start: string, end: string, language: string, zip: string): Promise<IAPIResult> {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Access-Control-Allow-Credentials', 'true');
        myHeaders.append('GET', 'POST');

        var raw = JSON.stringify({ "start": start, "end": end, "ZipCode": zip, "Language": language });
        var url = ApiConfig.API_CONTROLLER_CALENDAR + ApiConfig.API_METHOD_GET_TIME_SLOTS;

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        let apiResult = {} as IAPIResult;
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            apiResult.error = new Error(await response.text());
            return apiResult;
        }

        apiResult.payload = await response.json();
        return apiResult;
    }

    public async getClosedDays(year: number, month: number, language: string, zip: string): Promise<IAPIResult> {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Access-Control-Allow-Credentials', 'true');

        var raw = JSON.stringify({ "year": year, "month": month, "ZipCode": zip, "Language": language });
        var url = ApiConfig.API_CONTROLLER_CALENDAR + ApiConfig.API_METHOD_GET_CLOSED_DAYS;

        var requestOptions: RequestInit = {
            headers: myHeaders,
            body: raw,
            method: 'POST',
        };

        let apiResult = {} as IAPIResult;
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            apiResult.error = new Error(await response.text());
            return apiResult;
        }

        apiResult.payload = await response.json();
        return apiResult;
    }

    public async makeBooking(timeSlotBooking: ITimeSlotBooking): Promise<IAPIResult> {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Access-Control-Allow-Credentials', 'true');
        myHeaders.append('GET', 'POST');

        var raw = JSON.stringify(timeSlotBooking);
        var url = ApiConfig.API_CONTROLLER_CALENDAR + ApiConfig.API_METHOD_POST_MAKE_BOOKING;

        var requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        let apiResult = {} as IAPIResult;
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            apiResult.error = new Error(await response.text());
            return apiResult;
        }

        apiResult.payload = await response.json();
        return apiResult;
    }

    public async makeBookingWithRealNum(timeSlotBooking: ITimeSlotBooking): Promise<IAPIResult> {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Accept', 'application/json');
        myHeaders.append('Access-Control-Allow-Credentials', 'true');
        myHeaders.append('GET', 'POST');

        var raw = JSON.stringify(timeSlotBooking);
        var url = ApiConfig.API_CONTROLLER_CALENDAR + ApiConfig.API_METHOD_POST_APM_BOOK_WITH_REAL_NUM;
        var requestOptions: RequestInit = { method: 'POST', headers: myHeaders, body: raw };

        let apiResult = {} as IAPIResult;
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            apiResult.error = new Error(await response.text());
            return apiResult;
        }

        apiResult.payload = await response.json();
        return apiResult;
    }
}

