import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Home from "./components/home";
import Footer from "./layout/footer"
import Header from "./components/header"
import UserQuestion from "./components/user_question";
import CookiePolicy from "./components/cookie_policy";
import PrivacyConditions from "./components/privacy_conditions";
import TermsAndConditions from "./components/terms_conditions";
import CarInsurance from "./components/iframe/car_insurance";
import CalculateCapital from "./components/iframe/calculate_capital";
import InsuranceRenting from "./components/insurance/insurance_renting";
import TransactionResult from "./components/payments/transaction_result";
import ContactMe from "./components/contact_me";
import CallInterceptions from "./components/call_interceptions";
import Error from "./components/error";
import Appointment from "./components/appointment";
import Claims from "./components/claims";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './i18n';
import { Container } from 'react-bootstrap';
import Intro from './components/intro';
import "react-datepicker/dist/react-datepicker.css"
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <Router>
    {window.location.href.indexOf("iframe") == -1 && <Header />}
    <div>
      <Container fluid >
        <Switch>
          <Route exact path="/iframe_appointment/:lang"><Appointment /></Route>
          <Route exact path="/iframe_user_question/:lang"><UserQuestion /></Route>
          <Route exact path="/iframe_claims/:lang"><Claims /></Route>
          <Route exact path="/online-schade-melden/:lang"><Claims /></Route>
          <Route exact path="/signaler-un-dommage/:lang"><Claims /></Route>
          <Route exact path="/iframe_online-schade-melden/:lang"><Claims /></Route>
          <Route exact path="/iframe_signaler-un-dommage/:lang"><Claims /></Route>
          <Route path="/home"><Home /></Route>
          <Route path="/claims"><Claims /></Route>
          <Route path="/user_question"><UserQuestion /></Route>
          <Route path="/cookie_policy"><CookiePolicy /></Route>
          <Route path="/privacy_conditions"><PrivacyConditions /></Route>
          <Route path="/appointment"><Appointment /></Route>
          <Route path="/terms_and_conditions"><TermsAndConditions /></Route>
          <Route path="/call_interceptions/:relnum/:zipcode/:language"><CallInterceptions /></Route>
          <Route path="/user_question"><UserQuestion /></Route>
          <Route path="/iframe_contact_me/:lang"><ContactMe /></Route>
          <Route path="/iframe_insurance_renting/:lang"><InsuranceRenting /></Route>
          <Route path="/error"><Error /></Route>
          <Route path="/car_insurance/:lang"><CarInsurance /></Route>
          <Route path="/calculate_capital/:lang"><CalculateCapital /></Route>
          <Route path="/insurance_renting"><InsuranceRenting /></Route>
          <Route path="/transaction_result/:lang/:id"><TransactionResult /></Route>
          <Route path="/"><Intro /></Route>
          {/* <Route path="/"><App /></Route> */}
        </Switch>
      </Container>
    </div>
    {window.location.href.indexOf("iframe") == -1 && <Footer />}
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//quick meeting
//604 erik de ru
serviceWorker.unregister();
