import React, { useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18n from "../../i18n";


const ErrorMessage = (props) => {

    const { t } = useTranslation();

    useEffect(() => {

    }, [])

    return (
        <Row style={{ textAlign: "center", marginTop: 50, marginBottom: 20 }}>
            <Col md={{ offset: 1, span: 10 }} xs={11} className="options_message_purple">
                <label>{props.message}</label>
            </Col>
        </Row>
    )
}

export default ErrorMessage;