import React, { useState } from "react";
import { Row } from "react-bootstrap";
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Globals } from "./global";

const MyGoogleReCaptcha = (props) => {

    const [captcha, setCaptcha] = useState<string>();

    return (
        <Row style={{ textAlign: "center", marginTop: 50 }}>
            <GoogleReCaptchaProvider
                reCaptchaKey={Globals.GOOGLE_CAPTHA_KEY}>
                <GoogleReCaptcha onVerify={e => setCaptcha(e)} />
            </GoogleReCaptchaProvider>
        </Row>
    );
}


export default MyGoogleReCaptcha;
