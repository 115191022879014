import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";

const WarningMessage = (props) => {

    return (
        <div>
            <div className="warning_component" >
                <div className="warning_message">
                    <Row className="justify-content-md-center">
                        <FontAwesomeIcon icon={faExclamationTriangle} size="6x"
                            style={{ color: "#80BC00" }} />
                    </Row>
                    <br />
                    <Row className="justify-content-md-center" >
                        <Col xs={12} md={3}>
                            <span className="title__dots_error">
                                <span className="title_dot title__dots__dot--green"></span>
                                <span className="title_dot title__dots__dot--blue"></span>
                                <span className="title_dot title__dots__dot--red"></span>
                            </span><br />
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                        {props.message}
                    </Row>
                    <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                        <Button onClick={(e) => props.click()}
                            style={{ marginTop: 20, color: "white", fontWeight: 900, fontSize: 20 }}
                            variant="warning" className="CDA_button CDA_button_green">Take me home</Button>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default WarningMessage;
