import React, { useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Home() {

    const { t } = useTranslation();

    return (
        <div>
            <Row className="justify-content-md-center " md={{ cols: 12 }}>
                <Col>
                    <h1 className="title">
                        <b>{t('HOME_CONTACT_US')}</b>
                        <span className="title__dots">
                            <span className="title_dot title__dots__dot--green"></span>
                            <span className="title_dot title__dots__dot--blue"></span>
                            <span className="title_dot title__dots__dot--red"></span>
                        </span>
                    </h1>
                </Col>
            </Row>
            <Row className="justify-content-md-center content" md={{ cols: 12 }}>
                <Col md={{ span: 4 }}>
                    <Link to="/appointment" className="call-to-action-container__call-to-action">
                        <img src="/kalender.svg" alt="" />
                        {t('HOME_I_WANT_AN_APPOINTMENT')}
                    </Link>
                </Col>
                <Col md={{ span: 4 }}>
                    <Link className="call-to-action-container__call-to-action"
                        to="/user_question">
                        <img src="/vraag.svg" alt="" />
                        {t('HOME_I_HAVE_A_QUESTION')}
                    </Link>
                </Col>
            </Row>
        </div >
    )
}