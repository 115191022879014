import ApiConfig from '../../src/configuration/apiConfiguration';

const getDays = (year, month, language, zip) => {

    var axios = require('axios');
    var raw = JSON.stringify({ "year": year, "month": month, "ZipCode": zip, "Language": language });
    var url = ApiConfig.API_CONTROLLER_CALENDAR + ApiConfig.API_METHOD_GET_CLOSED_DAYS;

    var config = {
        method: 'post',
        data: raw,
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
    };

    return axios(config);
}

const search = (start, end, language, zip) => {

    var axios = require('axios');

    var raw = JSON.stringify({ "start": start, "end": end, "ZipCode": zip, "Language": language });
    var url = ApiConfig.API_CONTROLLER_CALENDAR + ApiConfig.API_METHOD_GET_TIME_SLOTS;

    var config = {
        method: 'post',
        data: raw,
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
    };

    return axios(config);
}


export default
    {
        getDays,
        search
    }