import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Row, Col, Form, Alert, Button, Table } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useDropzone } from "react-dropzone";
import { ClaimsService } from './../services/ClaimsService';
import ClaimService from './../services/ClaimService';
import { IClaimService } from './../services/contracts/IClaimsService';
import { IAPIResult } from "../models/IAPIResult";
import { useHistory, useParams } from "react-router-dom";
import SuccesMessage from "./general/succes";
import logoPdf from '../images/pdf.jpg';

export default function Claims() {
    const previewStyle = {
        display: 'inline',
        width: 100,
        height: 100,
    };
    const maxSize = 1048576;
    const { t, i18n } = useTranslation();
    const [totalSize, setTotalSize] = useState(0);
    const history = useHistory();
    const [showSucces, setShowSucces] = useState(false);
    const [hideForm, setHideForm] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    let myValidationSchema = yup.object().shape({
        relation_number: yup.string().required(t('claims:ERRORS_RELATION_NUMBER')),
        polis_number: yup.string().required(t('claims:ERRORS_POLIS_NUMBER')),
        phone: yup.string().required(t('ERRORS_PHONE_REQUIRED')).matches(/^[0-9]{10}$/, t('ERRORS_PHONE_INVALID')),
        email: yup.string().required(t('ERRORS_EMAIL_REQUIRED')).email(t('ERRORS_EMAIL_VALID')),
        message: yup.string().required(t('ERRORS_MESSAGE_REQUIRED')),
    });

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            relation_number: "",
            polis_number: "",
            phone: "",
            email: "",
            message: "",
        },
        resolver: yupResolver(myValidationSchema),
    });

    const [files, setFiles] = useState<File[]>([]);
    const { lang }: any = useParams()

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        getFilesFromEvent: event => myCustomFileGetter(event),
        accept: 'image/png,image/jpg, pdf',
        maxFiles: 10,
        minSize: 0,
        maxSize
    })

    useEffect(() => {
        if (lang)
            i18n.changeLanguage(lang.toLowerCase());
        else
            i18n.changeLanguage("nl");
    }, [])


    async function myCustomFileGetter(event) {

        const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

        for (var i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);

            Object.defineProperty(file, 'myProp', {
                value: true
            });


            if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf") {
                setTotalSize(totalSize + file.size);
                files.push(file);
            }
        }

        return files;
    }

    const removeFile = (name) => {
        setFiles(files.filter((e) => (e.name !== name)))
    }

    const onSubmit = async (data) => {

        setShowSpinner(true);
        setHideForm(true);
        let phone = data["phone"];
        let email = data["email"];
        let polis_number = data["polis_number"];
        let relation_number = data["relation_number"];
        let message = data["message"];

        // const claimsService: IClaimService = new ClaimsService();
        // const result: IAPIResult = await claimsService.create(phone, email, polis_number, relation_number, message, files);

        // if (result.error != null)
        //     history.push("/error");

        // setShowSpinner(false);
        // setShowSucces(true);



        ClaimService.create(phone, email, polis_number, relation_number, message, files).then(reponse => {
            setShowSucces(true);
            setShowSpinner(false);

        }).catch(error => {
            history.push("/error");
        });
    };

    const calculateTotalSize = (size) => {
        return ((size / 1024) / 1024).toFixed(2);
    }

    return (
        <div className="content">
            <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                <img src="/loader.svg" alt="" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} hidden={hideForm}>
                <Row style={{ marginBottom: 20 }} className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 3, offset: 0 }}>
                        <Form.Label>{t('claims:RELATION_NUMBER')}</Form.Label><span className="error_span" />
                        <Form.Control type="text" placeholder="" ref={register} name="relation_number"
                            id="relation_number" />
                        {errors.relation_number && <Alert className="myAlert" variant="danger">{errors.relation_number?.message}</Alert>}
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 3, offset: 0 }}>
                        <Form.Label>{t('POLIS_NUMBER')}</Form.Label><span className="error_span" />
                        <Form.Control type="text" placeholder="" ref={register} name="polis_number"
                            id="polis_number" />
                        {errors.polis_number && <Alert className="myAlert" variant="danger">{errors.polis_number?.message}</Alert>}
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 3, offset: 0 }}>
                        <Form.Label>{t('FORM_EMAIL')}</Form.Label><span className="error_span" />
                        <Form.Control type="text" placeholder="" ref={register} name="email"
                            id="email" />
                        {errors.email && <Alert className="myAlert" variant="danger">{errors.email?.message}</Alert>}
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 3, offset: 0 }}>
                        <Form.Label>{t('FORM_PHONE')}</Form.Label><span className="error_span" />
                        <Form.Control type="text" placeholder="" ref={register} name="phone"
                            id="phone" />
                        {errors.phone && <Alert className="myAlert" variant="danger">{errors.phone?.message}</Alert>}
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }}>
                        <Form.Label>{t('FORM_CLAIMS_REASON')}</Form.Label><span className="error_span" />
                        <Form.Control as="textarea"
                            rows={5}
                            name="message"
                            id="message"
                            ref={register} />
                        {errors.message && <Alert className="myAlert" variant="danger">
                            {errors.message?.message}</Alert>}

                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }}>
                        <div {...getRootProps()} className="my_dropzone">
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <div>
                                        <p>{t('claims:CLAIMS_DRAG_DROP_FILES')}</p>
                                        <em>{t('claims:CLAIMS_JPG_PNG')}</em>
                                    </div>
                            }
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }}>
                        <div hidden={files.length == 0}>
                            <Row style={{ marginBottom: 20, borderBottom: "1px solid grey" }}  >
                                <Col xs={5} >Preview</Col>
                                <Col xs={5} >Name</Col>
                                <Col xs={2}><div style={{ textAlign: 'center' }}>Action</div></Col>
                            </Row>
                            {files.map((item, index) => (
                                <Row key={item.name}>
                                    <Col xs={5} style={{ marginBottom: 20 }}>
                                        {
                                            item.type === "application/pdf" ? <img className="pdfPreviewIcon" src={logoPdf} alt="logo" /> :
                                                <img
                                                    alt="Preview"
                                                    key={item.name}
                                                    src={URL.createObjectURL(item)}
                                                    style={previewStyle}
                                                />
                                        }
                                    </Col>
                                    <Col xs={5}>{item.name}</Col>
                                    <Col xs={2} style={{ marginBottom: 20 }}>
                                        <Button onClick={e => removeFile(item.name)} className="CDA_button CDA_button_purple" style={{ margin: 0, width: '100%' }}
                                            variant="danger">{t('FORM_BUTTON_DELETE')}</Button></Col>
                                </Row>
                            ))}
                            <Row>
                                <Col>Total size:</Col>
                                <Col>{calculateTotalSize(totalSize)} MB</Col>
                            </Row>
                        </div >
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} className="justify-content-md-center">
                    <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 0 }}>
                        {parseFloat(calculateTotalSize(totalSize)) < 26 ?
                            <Button style={{ margin: 0, width: "100%" }}
                                className="CDA_button CDA_button_green btn-block"
                                type="submit" variant="success">{t('FORM_BUTTON_SEND')}</Button> :
                            <div className="pictureSizeWarning">
                                De foto's mogen maximaal 26 MB bevatten
                            </div>}
                    </Col>
                </Row>
            </form>
            <div hidden={!showSucces}>
                <SuccesMessage message={t('claims:CLAIMS_CONFIRMATION')} hide={showSpinner} />
            </div>
        </div >
    );
}
