import { IAPMBooking } from "../models/IAPMBooking";
import ApiConfig from '../../src/configuration/apiConfiguration';

const makeBooking = (apmBooking: IAPMBooking) => {

    var axios = require('axios');
    var data = JSON.stringify(apmBooking);
    var config = {
        method: 'post',
        data: data,
        url: ApiConfig.API_CONTROLLER_APM + ApiConfig.API_METHOD_POST_APM_BOOK,
        headers: {
            'Content-Type': 'application/json'
        },
    };

    return axios(config);
}

export default
    {
        makeBooking
    }

