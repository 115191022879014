import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MyGoogleReCaptcha from "./general/myGoogleReCaptcha";
import { IAPMBooking } from './../models/IAPMBooking';
import APMService from './../services/APMService';
import { IAPMService } from "../services/contracts/IAPMService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import i18n from "../i18n";
import PrivacyCondition from "./general/privacy_condition";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

export default function UserQuestion() {
    const { t } = useTranslation();
    const { lang }: any = useParams();
    const history = useHistory();
    const [showSpinner, setShowSpinner] = useState(false);
    const [showSuccesMessage, setShowSuccesMessage] = useState(false);
    let myValidationSchema = yup.object().shape({
        firstname: yup.string().required(t('ERRORS_FIRST_NAME_REQUIRED')),
        name: yup.string().required(t('ERRORS_NAME_REQUIRED')),
        phone: yup.string().required(t('ERRORS_PHONE_REQUIRED')).matches(/^[0-9]{10}$/, t('ERRORS_PHONE_INVALID')),
        email: yup.string().required(t('ERRORS_EMAIL_REQUIRED')).email(t('ERRORS_EMAIL_VALID')),
        message: yup.string().required(t('ERRORS_MESSAGE_REQUIRED')),
        agreement: yup.boolean().oneOf([true], t('ERRORS_I_AGREE_TERMS_AND_CONDITIONS_REQUIRED')),
    });

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            firstname: "",
            name: "",
            phone: "",
            email: "",
            message: "",
            agreement: false,
        },
        resolver: yupResolver(myValidationSchema),
    });

    useEffect(() => {
        if (lang) {
            i18n.changeLanguage(lang.toLowerCase());
        }

    }, [])

    const onSubmit = async (data) => {
        setShowSpinner(true);
        let apmBooking = {} as IAPMBooking;
        apmBooking.firstname = data["firstname"];
        apmBooking.name = data["name"];
        apmBooking.phone = data["phone"];
        apmBooking.email = data["email"];
        apmBooking.message = data["message"];

        APMService.makeBooking(apmBooking).then(response => {
            setShowSuccesMessage(true);
            setShowSpinner(false);
        }).catch(response => { setShowSpinner(false); history.push('/error'); });
    }

    return (
        <div className="content">
            <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                <img src="/loader.svg" alt="" />
            </div>
            {showSuccesMessage && <div >
                <Row className="confirmation_booking_message" >
                    <Col >
                        <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: "#92BB4A" }} />
                    </Col>
                </Row>
                <Row className="confirmation_booking_message" >
                    <Col><p>{t('QUESTION_CONFIRMED')}</p>
                    </Col>
                </Row>
            </div>}
            {!showSuccesMessage && <div hidden={showSpinner}>
                <Row className="justify-content-md-center">
                    <Col xs={{ span: 10 }}>
                        <h1 className="title">
                            {t('HOME_I_HAVE_A_QUESTION')}
                            <span className="title__dots">
                                <span className="title_dot title__dots__dot--green"></span>
                                <span className="title_dot title__dots__dot--blue"></span>
                                <span className="title_dot title__dots__dot--red"></span>
                            </span>
                        </h1>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col xs={{ span: 11 }} sm={{ offset: 1, span: 3 }} >
                            <Form.Label>{t('FORM_FIRST_NAME')}</Form.Label><span className="error_span" />
                            <Form.Control type="text" placeholder="" ref={register} name="firstname"
                                id="firstname" />
                            {errors.firstname && <Alert className="myAlert" variant="danger">{errors.firstname?.message}</Alert>}
                        </Col>
                        <Col xs={{ span: 11 }} sm={{ span: 3 }} >
                            <Form.Label>{t('FORM_LAST_NAME')}</Form.Label><span className="error_span" />
                            <Form.Control type="text"
                                placeholder=""
                                name="name"
                                id="name"
                                ref={register}
                            />
                            {errors.name && <Alert className="myAlert" variant="danger">{errors.name?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col xs={{ span: 11 }} sm={{ offset: 1, span: 3 }} >
                            <Form.Label>{t('FORM_EMAIL')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="email"
                                id="email"
                                placeholder=""
                                ref={register} />
                            {errors.email && <Alert className="myAlert" variant="danger">{errors.email?.message}</Alert>}
                        </Col>
                        <Col xs={{ span: 11 }} sm={{ span: 3 }} >
                            <Form.Label>{t('FORM_PHONE')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                placeholder=""
                                name="phone"
                                id="phone"
                                ref={register} />
                            {errors.phone && <Alert className="myAlert" variant="danger">{errors.phone?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col xs={{ span: 11 }} sm={{ span: 6, offset: 1 }} >
                            <Form.Label>{t('FORM_MESSAGE')}</Form.Label><span className="error_span" />
                            <Form.Control as="textarea"
                                rows={10}
                                name="message"
                                id="message"
                                ref={register} />
                            {errors.message && <Alert className="myAlert" variant="danger">{errors.message?.message}</Alert>}

                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col xs={{ span: 11 }} sm={{ span: 6, offset: 1 }} >
                            <Form.Check inline aria-label="option 1" ref={register}
                                id="agreement"
                                name="agreement" style={{ marginRight: "-3px" }} />
                            <PrivacyCondition />
                            {errors.agreement && <Alert className="myAlert" variant="danger">{errors.agreement?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col xs={{ span: 11 }} sm={{ span: 6, offset: 1 }} >
                            <Form.Label><span className="error_span" /> {t('FORM_REQUIRED_FIELD')}</Form.Label>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center" style={{ textAlign: "center", marginBottom: 50 }}>
                        {/* <Col xs={{ offset: 1, span: 10 }} md={{ offset: 3, span: 1 }} >
                            <Link className="btn btn-success CDA_button CDA_button_green" to="/">{t('BACK_TO_HOME_SCREEN')}</Link>
                        </Col>*/}
                        <Col xs={{ offset: 1, span: 10 }} md={{ span: 4 }} >
                            <Button type="submit" variant="success" className="CDA_button CDA_button_green">{t('FORM_BUTTON_CONFIRM')}</Button>
                        </Col>
                    </Row>

                </form>
            </div>}
        </div >
    )
}