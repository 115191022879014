export const Globals =
{
    ERROR_MESSAGES_NAME_REQUIRED: "The name is required.",
    ERROR_MESSAGES_CUSTOMER_REQUIRED: "The customer is required.",
    ERROR_MESSAGES_DESCRIPTION_REQUIRED: "The description is required.",
    ERROR_MESSAGES_PROJECT_CODE_REQUIRED: "The project code is required.",
    ERROR_MESSAGES_RATE_TYPE_REQUIRED: "The rate type is required.",
    ERROR_MESSAGES_RATE_AMOUNT_REQUIRED: "The rate amount is required.",
    ERROR_MESSAGES_INVALID_CURRENCY: "The currency input is not valid.",

    API_BASE_URL: "https://api.cda.be:21996/",
    // API_BASE_URL: "http://cda.sbsoftware.be/api/",
    //API_BASE_URL: "https://localhost:44369/",
    API_CONTROLLER_CALENDAR: "Calendar/",
    API_CONTROLLER_APM: "APM/",
    API_CONTROLLER_CLAIMS: "Claims/",
    API_CONTROLLER_PAYMENT: "Payment/",
    API_CONTROLLER_RENTING_INSURANCE: "RentingInsurance/",

    API_METHOD_GET_TIME_SLOTS: "getTimeSlots",
    API_METHOD_GET_CLOSED_DAYS: "getClosedDays",
    API_METHOD_POST_MAKE_BOOKING: "bookTimeSlot",
    API_METHOD_POST_CLAIMS_CREATE: "create",
    API_METHOD_POST_APM_BOOK: "book",
    API_METHOD_POST_APM_BOOK_WITH_REAL_NUM: "bookTimeSlotWithRealNum",
    API_METHOD_POST_MAKE_PAYMENT: "requestPayment",
    API_METHOD_POST_VERIFY_PAYMENT: "verifypayment",
    API_METHOD_POST_PAYMENT_STATUS: "Paymentstatus",
    API_METHOD_POST_MAKE_CALCULATION: "makecalculation",

    GOOGLE_CAPTHA_KEY: "6Lej7dsZAAAAABMQOW63sH61v8sPJ2VwXMlurzRr",

    //"MOLLIE_PAYMENTS_URL": "https://api.mollie.com/v2/payments",
    //"MOLLIE_PAYMENTS_WEBHOOK": "https://api.cda.be:21996/payments/verifyPayment",
    //"MOLLIE_PAYMENTS_WEBHOOK": "http://cda.sbsoftware.be/api/payment/verifyPayment",
    // "MOLLIE_PAYMENTS_WEBHOOK": "https://localhost:44369/payment/verifyPayment",
    //"MOLLIE_REDIRECT_URL": "https://api.cda.be:21996/transaction_result",
    "MOLLIE_REDIRECT_URL": "https://tools.cda.be/transaction_result"
}