import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './CDA.css';
import { BrowserRouter as Router, Link } from "react-router-dom";
import i18n from "../i18n"

const Header = () => {

    const [nlActive, setNLActive] = useState(true);
    const [frActive, setFRActive] = useState(false);

    async function HandleLanguageSelect(language) {
        i18n.changeLanguage(language)

        if (language == "nl") {
            setNLActive(true);
            setFRActive(false);
        }
        else {
            setNLActive(false);
            setFRActive(true);
        }
    }

    return (
        <div>
            <header className="header">
                <div className="">
                    <Container fluid>
                        <Row className="">
                            <Col xs={12}>
                                <Link to="/home">
                                    <img className="header_img" src="/logo.png" alt="" />
                                </Link>
                                <div className="language-selector">
                                    <ul>
                                        <li className={nlActive ? "active" : ""} value="nl" onClick={(e) => HandleLanguageSelect("nl")}><a> NL</a></li> |
                                        <li className={frActive ? "active" : ""} value="fr" onClick={(e) => HandleLanguageSelect("fr")}><a>FR</a>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header >
        </div >
    )
};

export default Header;