export const FormatCurrency = (input) => {
    return parseFloat(input).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}


export const GenerateGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const generateUniqueId = function () {
    return Date.now().toString(36) + Math.random().toString(36).substring(2, 32) + Math.random().toString(36).substring(2, 36) + Math.random().toString(36).substring(2, 36) + Math.random().toString(36).substring(2, 36);
}
export const convertToBoolean = (input) => {
    if (input === "true")
        return true;

    return false
}
