import { IRentingInsuranceCalculationRequest } from "../models/IRentingInsuranceCalculationRequest";
import ApiConfig from '../../src/configuration/apiConfiguration';

const calculateRentingInsurance = (rentingInsuranceCalculationRequest: IRentingInsuranceCalculationRequest) => {

    var axios = require('axios');
    var data = JSON.stringify(rentingInsuranceCalculationRequest);

    var url = ApiConfig.API_CONTROLLER_RENTING_INSURANCE + ApiConfig.API_METHOD_POST_MAKE_CALCULATION;

    var config = {
        method: 'post',
        url: url,
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config);
}

export default
    {
        calculateRentingInsurance
    }
