import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from "react-router-dom";


export default function Error() {
    const history = useHistory();

    const handleClick = () => {
        history.push("/");
    }

    return (
        <div className="error_component" >
            <div className="error_message">
                <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="6x"
                        style={{ color: "#80BC00" }} />
                </Row>
                <br />
                <Row className="justify-content-md-center" >
                    <Col xs={12} md={3}><span className="SomeThingWentWrong">
                        Oops</span>
                        {/* </Col>
                    <Col xs={11} md={2}> */}
                        <span className="title__dots_error">
                            <span className="title_dot title__dots__dot--green"></span>
                            <span className="title_dot title__dots__dot--blue"></span>
                            <span className="title_dot title__dots__dot--red"></span>
                        </span><br />
                    </Col>
                </Row>
                <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                    <Col xs={12} ><span className="SomeThingWentWrong_subtitle">
                        something went wrong!</span>
                    </Col>
                </Row>
                <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                    An unexpected error has occured. Please try again later.<br />
                Contact support if the error persists.
            </Row>
                <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                    <Button onClick={(e) => handleClick()}
                        style={{ marginTop: 20, color: "white", fontWeight: 900, fontSize: 20 }}
                        variant="warning" className="CDA_button CDA_button_green">Take me home</Button>
                </Row>
            </div>

        </div>
    )
}