import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import claims_fr from "../src/translations/claims_fr.json";
import claims_nl from "../src/translations/claims_nl.json";
import general_nl from "../src/translations/general_nl.json";
import general_fr from "../src/translations/general_fr.json";
import cookies_fr from "../src/translations/cookies_fr.json";
import cookies_nl from "../src/translations/cookies_nl.json";
import payments_fr from "../src/translations/payments_fr.json";
import payments_nl from "../src/translations/payments_nl.json";
import renting_insurcance_fr from "../src/translations/renting_insurcance_fr.json";
import renting_insurcance_nl from "../src/translations/renting_insurcance_nl.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation: {
            "Welcome to React": "Welcome to React and react-i18next"
        }
    },
    fr: {
        lng: 'fr',
        claims: claims_fr,
        cookies: cookies_fr,
        general: general_fr,
        payments: payments_fr,
        renting_insurcance: renting_insurcance_fr,
        translation: {

        }
    },
    nl: {
        lng: 'nl',
        claims: claims_nl,
        cookies: cookies_nl,
        general: general_nl,
        payments: payments_nl,
        renting_insurcance: renting_insurcance_nl,
        translation: {

        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        defaultNS: 'general',
        resources,
        lng: 'nl',
        default: 'nl',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;