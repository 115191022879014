const API_BASE_URL = "https://api.cda.be:21996/";
// API_BASE_URL= "http=//cda.sbsoftware.be/api/",
//const API_BASE_URL = "https://localhost:44369/";
const API_CONTROLLER_CALENDAR = API_BASE_URL + "Calendar/";
const API_CONTROLLER_APM = API_BASE_URL + "APM/";
const API_CONTROLLER_CLAIMS = API_BASE_URL + "Claims/";
const API_CONTROLLER_PAYMENT = API_BASE_URL + "Payment/";
const API_CONTROLLER_RENTING_INSURANCE = API_BASE_URL + "RentingInsurance/";

const API_METHOD_GET_TIME_SLOTS = "getTimeSlots";
const API_METHOD_GET_CLOSED_DAYS = "getClosedDays";
const API_METHOD_POST_MAKE_BOOKING = "bookTimeSlot";
const API_METHOD_POST_CLAIMS_CREATE = "create";
const API_METHOD_POST_APM_BOOK = "book";
const API_METHOD_POST_APM_BOOK_WITH_REAL_NUM = "bookTimeSlotWithRealNum";
const API_METHOD_POST_VERIFY_PAYMENT = "verifypayment";
const API_METHOD_POST_PAYMENT_STATUS = "paymentstatus";
const API_METHOD_POST_MAKE_CALCULATION = "makecalculation";
const API_METHOD_POST_REQUEST_PAYMENT = "requestPayment";

export default
    {
        API_CONTROLLER_CALENDAR,
        API_CONTROLLER_APM,
        API_CONTROLLER_CLAIMS,
        API_CONTROLLER_PAYMENT,
        API_CONTROLLER_RENTING_INSURANCE,
        API_METHOD_GET_TIME_SLOTS,
        API_METHOD_GET_CLOSED_DAYS,
        API_METHOD_POST_MAKE_BOOKING,
        API_METHOD_POST_CLAIMS_CREATE,
        API_METHOD_POST_APM_BOOK,
        API_METHOD_POST_APM_BOOK_WITH_REAL_NUM,
        API_METHOD_POST_VERIFY_PAYMENT,
        API_METHOD_POST_PAYMENT_STATUS,
        API_METHOD_POST_MAKE_CALCULATION,
        API_METHOD_POST_REQUEST_PAYMENT
    }//