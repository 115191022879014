import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useParams } from "react-router-dom";
import PaymentService from "../../services/PaymentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

export default function TransactionResult() {

    const { t } = useTranslation();
    const { lang }: any = useParams()
    const [showSuccesMessage, setShowSuccesMessage] = useState(false);
    const [showFailureMessage, setShowFailureMessage] = useState(false);
    const { id }: any = useParams()
    const [loading, setloading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        verifyPayment();

        if (lang) {
            i18n.changeLanguage(lang.toLowerCase())
        }
    }, [])

    const verifyPayment = async () => {
        setloading(true);

        PaymentService.retrieveStatus(id).then(response => {
            if (response.data === "paid")
                setShowSuccesMessage(true);
            else
                setShowFailureMessage(true);
        }).catch(error => {
            history.push('/error');
        });

        setloading(!loading);
    }

    return (
        <div className="content">
            {loading ? (
                <div style={{ textAlign: "center" }}>
                    <img src="/loader.svg" alt="" />
                    <p>Verifying transaction</p>
                </div>
            ) : (<div >
                <Row hidden={!showSuccesMessage} style={{ textAlign: "center", marginTop: 50, marginBottom: 20 }} >
                    <Col >
                        <div className="message_component" >
                            <div className="positive_message" >
                                <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                                    <FontAwesomeIcon icon={faThumbsUp} size="6x" style={{ color: "#80BC00" }} />
                                </Row>
                                <br />
                                <Row className="justify-content-md-center" >
                                    <Col xs={12} md={3}><span className="SomeThingWentWrong">
                                        {t('payments:SUPER')}</span>

                                        <span className="title__dots_error">
                                            <span className="title_dot title__dots__dot--green"></span>
                                            <span className="title_dot title__dots__dot--blue"></span>
                                            <span className="title_dot title__dots__dot--red"></span>
                                        </span><br />
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                                    <Col xs={12} ><span className="SomeThingWentWrong_subtitle">
                                        {t('payments:PAYMENT_RECEIVED_SUCCESFULLY')}</span>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                                    {t('payments:FOLLOW_UP')}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row hidden={!showFailureMessage} style={{ textAlign: "center", marginTop: 50, marginBottom: 20 }} >
                    <Col >
                        <div className="message_component" >
                            <div className="negative_message" >
                                <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="6x" style={{ color: "#973B8E" }} />
                                </Row>
                                <br />
                                <Row className="justify-content-md-center" >
                                    <Col xs={12} md={3}><span className="SomeThingWentWrong">
                                        {t('payments:OOOPS')}</span>

                                        <span className="title__dots_error">
                                            <span className="title_dot title__dots__dot--green"></span>
                                            <span className="title_dot title__dots__dot--blue"></span>
                                            <span className="title
                                            
                                            
                                            _dot title__dots__dot--red"></span>
                                        </span><br />
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                                    <Col xs={12} ><span className="SomeThingWentWrong_subtitle">
                                        {t('payments:PAYMENT_PROBLEM')}</span>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center" xs={{ cols: 12 }}>
                                    {t('payments:FOLLUW_UP_ERROR')}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>)}
        </div>
    );
}