import React, { useState } from 'react';
import { Row, Col, Form, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const InsuranceRiskAddress = (props) => {

    const { t } = useTranslation();

    useEffect(() => {
    }, [])

    return (
        <div>
            <Row style={{ marginBottom: 10 }}>
                <Col xs={11} md={{ offset: 2, span: 6 }} >
                    <Form.Label>{t('FORM_STREET')}</Form.Label><span className="error_span" />
                    <Form.Control
                        type="text"
                        name="ra_street"
                        id="ra_street"
                        placeholder=""
                        ref={props.register} />
                    {props.errors.ra_street && <Alert className="myAlert" variant="danger">{props.errors.ra_street?.message}</Alert>}
                </Col>
                <Col xs={11} md={{ span: 2 }} >
                    <Form.Label>{t('FORM_NUMBER')}</Form.Label><span className="error_span" />
                    <Form.Control
                        type="text"
                        name="ra_number"
                        id="ra_number"
                        placeholder=""
                        ref={props.register} />
                    {props.errors.ra_number && <Alert className="myAlert" variant="danger">{props.errors.ra_number?.message}</Alert>}
                </Col>

            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <Form.Label>{t('FORM_CITY')}</Form.Label><span className="error_span" />
                    <Form.Control
                        type="text"
                        name="ra_city"
                        id="ra_city"
                        placeholder=""
                        ref={props.register} />
                    {props.errors.ra_city && <Alert className="myAlert" variant="danger">{props.errors.ra_city?.message}</Alert>}
                </Col>
                <Col md={2} xs={11} >
                    <Form.Label>{t('FORM_ZIP')}</Form.Label><span className="error_span" />
                    <Form.Control
                        type="text"
                        name="ra_zip"
                        id="ra_zip"
                        placeholder=""
                        ref={props.register} />
                    {props.errors.ra_zip && <Alert className="myAlert" variant="danger">{props.errors.ra_zip?.message}</Alert>}
                </Col>
            </Row>
        </div>
    )
}
export default InsuranceRiskAddress

