import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from "react-bootstrap";

const SuccesMessage = (props) => {

    return (
        <div>
            <Row className="confirmation_booking_message" md={{ cols: 12 }}>
                <Col >
                    <FontAwesomeIcon icon={faCheckCircle} size="6x" style={{ color: "#80BC00" }} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 20 }} className="confirmation_booking_message" md={{ cols: 12 }}>
                <Col><p>{props.message}</p>
                </Col>
            </Row>
        </div>
    );
}

export default SuccesMessage;
