import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import CurrencyFormatter from '../general/CurrencyFormatter';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const CalculationSummary = (props) => {

    const { t } = useTranslation();

    useEffect(() => {
    }, [])

    return (
        <div>
            <Row className="justify-content-md-center">
                <Col style={{ textAlign: 'center' }}>
                    {t('renting_insurcance:SUMMARY')}
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 10 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <label>{t('renting_insurcance:INSURRED_CAPITAL')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "right" }}>
                    <label> <CurrencyFormatter data={props.calulationResult.totalCapital} /></label>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <label>{t('renting_insurcance:INSURRED_HOUSEHOLD')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "right" }}>
                    <label> <CurrencyFormatter data={props.calulationResult.totalHouseHold} /></label>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 10 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row >
                <Col md={{ offset: 7, span: 2 }} xs={11} style={{ textAlign: "center" }}>
                    <label>{t('renting_insurcance:CONTRACT_PRICE')}</label>
                </Col>
                <Col md={2} xs={11} style={{ textAlign: "center" }} >
                    <label>{t('renting_insurcance:CONTRACT_PRICE_FIRST_YEAR')}</label>
                </Col>
            </Row>
            <Row >
                <Col md={{ offset: 2, span: 5 }} xs={11} >
                    <label>{t('renting_insurcance:PAYMENT_YEARLY')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label><CurrencyFormatter data={props.calulationResult.yearPrice} /> </label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label><CurrencyFormatter data={props.calulationResult.yearPriceWithPromo} /> </label>
                </Col>
            </Row>
            <Row >
                <Col md={{ offset: 2, span: 5 }} xs={11} >
                    <label>{t('renting_insurcance:PAYMENT_6_MONTHS')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label><CurrencyFormatter data={props.calulationResult.halfYearPrice} /></label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label><CurrencyFormatter data={props.calulationResult.halfYearPriceWithPromo} /></label>
                </Col>
            </Row>
            <Row >
                <Col md={{ offset: 2, span: 5 }} xs={11} >
                    <label>{t('renting_insurcance:PAYMENT_3_MONTHS')}</label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label> <CurrencyFormatter data={props.calulationResult.quarterPrice} /></label>
                </Col>
                <Col xs={2} md={2} style={{ textAlign: "center" }}>
                    <label> <CurrencyFormatter data={props.calulationResult.quarterPriceWithPromo} /></label>
                </Col>
            </Row>
        </div >
    )
}

export default CalculationSummary

