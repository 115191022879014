import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { IPaymentRequest } from "../../models/IPaymentRequest";
import { IRentingInsuranceCalculationRequest } from "../../models/IRentingInsuranceCalculationRequest";
import { Globals } from "../general/global";
import CalculationService from "../../services/CalculationService";
import MyGoogleReCaptcha from "../general/myGoogleReCaptcha";
import PrivacyCondition from "../general/privacy_condition";
import PaymentService from "../../services/PaymentService";
import { FormatCurrency } from '../general/GlobalFunctions';
import { generateUniqueId } from './../general/GlobalFunctions';
import { IPaymentMetdaData } from './../../models/IPaymentMetdaData';
import { IAddress } from "../../models/IAddress";
import { ICustomer } from "../../models/ICustomer";
import CalculationSummary from './calculationSummary';
import "../../css/insurance_renting.css"
import { IRentingInsuranceCalculationRequestResponse } from "../../models/IRentingInsuranceCalculationRequestResponse";
import CalculationSummaryPaymentSelection from "./calculationSummaryPaymentSelection";
import RentingStepper from './rentingStepper';
import { ExitStatus } from "typescript";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormatter from "../general/CurrencyFormatter";
import InsuranceActive from './insuranceActive';
import { convertToBoolean } from '../general/GlobalFunctions';
import InsuranceOptions from './insuranceOptions';
import InsuranceQuestions from './insuranceQuestions';
import ErrorMessage from "./errorMessage";
import InsuranceRiskAddress from './insuranceRiskAddress';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
require('./../../../node_modules/react-datepicker/dist/react-datepicker.css')

export default function InsuranceRenting() {

    const initialCustomer: ICustomer = ({ firstname: "", lastname: "", email: "", phone: "", dateOfBirth: "", addresses: [] });
    const [customer, setCustomer] = useState<ICustomer>(initialCustomer);
    const { t, i18n } = useTranslation();
    const [showStep_1, setShowStep_1] = useState(true);
    const [showStep_2, setShowStep_2] = useState(false);
    const [showStep_3, setShowStep_3] = useState(false);
    const [showStep_4, setShowStep_4] = useState(false);
    const [showStep_5, setShowStep_5] = useState(false);
    const [showStep_6, setShowStep_6] = useState(false);
    const [alreadyInsurred, setAlreadyInsurred] = useState(false);
    const [currentInsuranceCompany, setCurrentInsuranceCompany] = useState();
    const [otherRiskAddress, setOtherRiskAddress] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [showSpinner, setShowSpinner] = useState(false);
    const [surfaceToBig, setSurfaceToBig] = useState(false);
    const [calcaultionMade, setCalcaultionMade] = useState(false);
    const [makingCalcultion, setMakingCalculation] = useState(false);
    const [polisStartDate, setPolisStartDate] = useState(new Date().getTime() + (1000 * 60 * 60 * 24));
    const [surface, setSurface] = useState<number>();
    const [numberOfGarages, setNumberOfGarages] = useState<number>();
    const [before1945, setBefore1945] = useState<string>('false');
    const [addic, setAddic] = useState<string>('');
    const [elevatorPresent, setElevatorPresent] = useState<string>('');
    const [addiotionalSpace, setAddiotionalSpace] = useState<string>('');
    const [legalInsuranceSingle, setLegalInsuranceSingle] = useState<boolean>(true);
    const [legalInsuranceFamily, setLegalInsuranceFamily] = useState<boolean>(false);
    const [legalInsuranceFamilyWithChildren, setLegalInsuranceFamilyWithChildren] = useState<boolean>(false);
    const [legalInsuranceFamilyOlderThen60, setLegalInsuranceFamilyOlderThen60] = useState<boolean>(false);
    const [legalInsuranceTheft, setLegalInsuranceTheft] = useState<boolean>(false);
    const [legalInsuranceCivilLiability, setLegalInsuranceCivilLiability] = useState<boolean>(false);
    const [legalInsuranceAssistanceToPeople, setLegalInsuranceAssistanceToPeople] = useState<boolean>(false);
    const [responseLiabilityQuestion, setResponseLiabilityQuestion] = useState<boolean>(false);
    const [responseContractSuspended, setResponseContractSuspended] = useState<boolean>(false);
    const { lang }: any = useParams();
    const [rentingInsuranceCalculationRequest, setRentingInsuranceCalculationRequest] = useState<IRentingInsuranceCalculationRequest>({});
    const [priceTotal, setPriceTotal] = useState<string>('');
    const initialcalulationResult: IRentingInsuranceCalculationRequestResponse = {
        "civilLiability": "0",
        "finalResult": "0",
        "legalInsurance": "0",
        "unitValue": "0",
        "intermediateValue": "0",
        "peakValue": "0",
        "valueGarages": "0",
        "subTotal": "0",
        "garageValue": "0",
        "indexation": "0",
        "rentingInsuranceTotal": "0",
        "theft": "0",
        "assistanceToPeople": "0", "conventionalValue": "0",
        "heightCoefficientCalculatie": "0",
        "yearPrice": "0",
        "quarterPrice": "0",
        "monthPrice": "0", "totalCapitalCalculated": "0", "totalHouseHoldCalculated": "0",
        "finalResultIncTaxes": "0",
        "totalHouseHold": "0",
        "halfYearPrice": "0",
        "promoAmount": "0",
        "promoActive": "false",
        "assistanceToPeopleTax": "",
        "assistanceToPeopleTotal": "",
        "civilLiabilityTax": "",
        "civilLiabilityTotal": "",
        "finalResultIncTaxesWithPromo": "",
        "finalResultWithPromo": "",
        "yearPriceWithPromo": "",
        "halfYearPriceWithPromo": "",
        "quarterPriceWithPromo": "",
        "monthPriceWithPromo": "",

    }
    const [calulationResult, setCalculationResult] = useState<IRentingInsuranceCalculationRequestResponse>(initialcalulationResult);
    const history = useHistory();

    useEffect(() => {
        makeCalculation()

        if (lang) {
            i18n.changeLanguage(lang.toLowerCase())
        }
    }, [surface, numberOfGarages, before1945, elevatorPresent, addic, legalInsuranceSingle, legalInsuranceFamily, legalInsuranceFamilyOlderThen60, legalInsuranceFamilyOlderThen60, legalInsuranceTheft, legalInsuranceCivilLiability, legalInsuranceAssistanceToPeople, addiotionalSpace])

    let myValidationSchema = yup.object().shape({
        firstname: yup.string().required(t('ERRORS_FIRST_NAME_REQUIRED')),
        name: yup.string().required(t('ERRORS_NAME_REQUIRED')),
        street: yup.string().required(t('ERRORS_STREET_REQUIRED')),
        number: yup.string().required(t('ERRORS_NUMBER_REQUIRED')),
        city: yup.string().required(t('ERRORS_CITY_REQUIRED')),
        zip: yup.string().matches(/^[0-9]{4}$/, t('ERRORS_ZIP_INCORRECT')),
        // phone: yup.string().matches(/^[0-9]{9}$/, t('ERRORS_PHONE_INVALID')),
        email: yup.string().required(t('ERRORS_EMAIL_REQUIRED')).email(t('ERRORS_EMAIL_VALID')),
        // dateofbirth: yup.string().required(t('ERROR_DATE_OF_BIRTH')),
        agreement: yup.boolean().oneOf([true], t('ERRORS_I_AGREE_TERMS_AND_CONDITIONS_REQUIRED')),
        surface: yup.number().typeError(t('ERRORS_SURFACE_REQUIRED')).moreThan(-1, t('ERRORS_SURFACE_REQUIRED')),
        numberOfGarages: yup.number().typeError(t('ERRORS_NUMBER_OF_GARAGES')).moreThan(-1, t('ERRORS_NUMBER_OF_GARAGES')),
        riskAddress: yup.boolean(),
        ra_street: yup.string().when("riskAddress", (riskAddress) => {
            if (riskAddress)
                return yup.string().required(t('ERRORS_STREET_REQUIRED'))
        }),
        ra_number: yup.string().when("riskAddress", (riskAddress) => {
            if (riskAddress)
                return yup.string().required(t('ERRORS_NUMBER_REQUIRED'))
        }),
        ra_zip: yup.string().when("riskAddress", (riskAddress) => {
            if (riskAddress)
                return yup.string().matches(/^[0-9]{4}$/, t('ERRORS_ZIP_INCORRECT'))
        }),
        ra_city: yup.string().when("riskAddress", (riskAddress) => {
            if (riskAddress)
                return yup.string().required(t('ERRORS_STREEERRORS_CITY_REQUIREDT_REQUIRED'))
        }),
    });

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            firstname: "",
            name: "",
            street: "",
            number: "",
            city: "",
            zip: "",
            phone: "",
            email: "",
            message: "",
            surface: 0,
            numberOfGarages: 0,
            agreement: false,
            dateofbirth: "",
        },
        resolver: yupResolver(myValidationSchema),
    });

    const requestPayment = async (paymentPeriod: number) => {
        setShowSpinner(true);

        if (!responseLiabilityQuestion && !responseContractSuspended) {
            let uniqueID = generateUniqueId();

            let metaData = {} as IPaymentMetdaData;
            metaData.unique_id = uniqueID;
            metaData.internal_type = "insurance_type";

            let paymentRequest = {} as IPaymentRequest;
            paymentRequest.description = "Payment for " + customer.firstname + " " + customer.lastname + " rental insurance";
            paymentRequest.redirectUrl = Globals.MOLLIE_REDIRECT_URL + "/" + i18n.languages[0] + "/" + uniqueID;
            paymentRequest.locale = "nl_BE";
            paymentRequest.metadata = metaData;
            paymentRequest.calculationRequest = rentingInsuranceCalculationRequest;
            paymentRequest.legalInsuranceCivilLiability = legalInsuranceCivilLiability;
            paymentRequest.legalInsuranceAssistanceToPeople = legalInsuranceAssistanceToPeople;
            paymentRequest.customer = customer;
            paymentRequest.alReadyInsurred = alreadyInsurred;
            paymentRequest.currentInsuranceCompany = currentInsuranceCompany;
            paymentRequest.paymentPeriod = paymentPeriod;

            PaymentService.makePayment(paymentRequest).then(response => {
                console.log(response.data);
                window.location.href = response.data._links.checkout.href;
            }).catch(error => { history.push('/error'); });
        }
        setShowSpinner(false);
    }

    const makeCalculation = async () => {
        setSurfaceToBig(false);
        setShowSpinner(true);

        if (surface! >= 200) {
            console.log(surface)
            setSurfaceToBig(true);
            return;
        }

        if (surface! > 0) {
            setMakingCalculation(true);
            rentingInsuranceCalculationRequest.surface = surface;
            rentingInsuranceCalculationRequest.numberOfGarages = numberOfGarages;
            rentingInsuranceCalculationRequest.before1945 = convertToBoolean(before1945);
            rentingInsuranceCalculationRequest.elevatorPresent = convertToBoolean(elevatorPresent);
            rentingInsuranceCalculationRequest.unfinishedAttic = convertToBoolean(addic);
            rentingInsuranceCalculationRequest.garagePresent = convertToBoolean(addiotionalSpace);
            rentingInsuranceCalculationRequest.legalInsuranceSingle = legalInsuranceSingle;
            rentingInsuranceCalculationRequest.legalInsuranceFamily = legalInsuranceFamily;
            rentingInsuranceCalculationRequest.legalInsuranceFamilyWithChildren = legalInsuranceFamilyWithChildren;
            rentingInsuranceCalculationRequest.legalInsuranceFamilyOlderThen60 = legalInsuranceFamilyOlderThen60;
            rentingInsuranceCalculationRequest.theft = legalInsuranceTheft;
            rentingInsuranceCalculationRequest.assistanceToPeople = legalInsuranceAssistanceToPeople;
            rentingInsuranceCalculationRequest.civilLiability = legalInsuranceCivilLiability;

            CalculationService.calculateRentingInsurance(rentingInsuranceCalculationRequest).then(response => {
                setCalcaultionMade(true);
                setMakingCalculation(false);
                setCalculationResult(response.data);
                setPriceTotal(response.data.finalResult);
                setMakingCalculation(false);
            }).catch(response => { console.error(response) });
        }

        setShowSpinner(false);
    }

    const legalInsuranceChange = (input) => {
        input === "1" ? setLegalInsuranceSingle(true) : setLegalInsuranceSingle(false);
        input === "2" ? setLegalInsuranceFamily(true) : setLegalInsuranceFamily(false);
        input === "3" ? setLegalInsuranceFamilyWithChildren(true) : setLegalInsuranceFamilyWithChildren(false);
        input === "4" ? setLegalInsuranceFamilyOlderThen60(true) : setLegalInsuranceFamilyOlderThen60(false);
    }

    const onSubmit = (data) => {

        let address = {} as IAddress;
        address.street = data["street"];
        address.number = data["number"];
        address.city = data["city"];
        address.zip = data["zip"];
        address.riskAddress = !otherRiskAddress;

        let ra_address = {} as IAddress;
        ra_address.street = data["ra_street"];
        ra_address.number = data["ra_number"];
        ra_address.city = data["ra_city"];
        ra_address.zip = data["ra_zip"];
        ra_address.riskAddress = otherRiskAddress;

        let customer = {} as ICustomer;
        customer.firstname = data["firstname"];
        customer.lastname = data["name"];
        customer.phone = data["phone"];
        customer.email = data["email"];
        customer.dateOfBirth = data["dateofbirth"];
        customer.addresses = [];
        customer.addresses.push(address);
        customer.addresses.push(ra_address);

        setCustomer(customer);
        setStepper(5);
    };

    const setStepper = (number) => {

        setShowStep_1(false);
        setShowStep_2(false);
        setShowStep_3(false);
        setShowStep_4(false);
        setShowStep_5(false);
        setShowStep_6(false);

        if (number === 1)
            setShowStep_1(true);

        if (number === 2)
            setShowStep_2(true);

        if (number === 3)
            setShowStep_3(true);

        if (number === 4)
            setShowStep_4(true);

        if (number === 5)
            setShowStep_5(true);

        if (number === 6)
            setShowStep_6(true);
    }

    const determinelegalInsuranceAssistanceToPeople = (input) => {
        setLegalInsuranceAssistanceToPeople(input.target.checked);
        console.log(legalInsuranceAssistanceToPeople);
    }

    const determineLegalInsuranceCivilLiability = (input) => {
        setLegalInsuranceCivilLiability(input.target.checked);
        console.log(legalInsuranceCivilLiability);
    }

    return (
        <div className="content">
            <Row className="justify-content-md-center" >
                <span className="title" >
                    {t('renting_insurcance:RENTING_INSURANCE')}
                    <span className="title__dots">
                        <span className="title_dot title__dots__dot--green"></span>
                        <span className="title_dot title__dots__dot--blue"></span>
                        <span className="title_dot title__dots__dot--red"></span>
                    </span>
                </span>
            </Row>
            <RentingStepper showStep_1={showStep_1} showStep_2={showStep_2} showStep_3={showStep_3} showStep_4={showStep_4} showStep_5={showStep_5} showStep_6={showStep_6} />
            <div hidden={!showStep_1} className="caculation_component">
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        <label>{t('renting_insurcance:QUESTION_SUFACE')}</label>
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Control type="text" name="surface" id="surface" ref={register} onChange={e => setSurface(parseInt(e.target.value))} />
                    </Col>
                </Row>
                <Row hidden={!errors.surface}>
                    <Col md={{ offset: 2, span: 7 }} xs={11} >
                        {errors.surface && <Alert className="myAlert" variant="danger">{errors.surface?.message}</Alert>}
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        <label>{t('renting_insurcance:QUESTION_NUMBER_OF_ROOMS')}</label>
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Control type="text" name="numberOfGarages" id="numberOfGarages" ref={register} onChange={e => setNumberOfGarages(parseInt(e.target.value))} />
                    </Col>
                </Row>
                <Row hidden={!errors.numberOfGarages}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        {errors.numberOfGarages && <Alert className="myAlert" variant="danger">{errors.numberOfGarages?.message}</Alert>}
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        <label>{t('renting_insurcance:QUESTION_BUILDING_YEAR')}</label>
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Control as="select"
                            onChange={(e) => setBefore1945(e.target.value)}
                            defaultValue="false">
                            <option value="false">{t('renting_insurcance:AFTER_1945')}</option>
                            <option value="true">{t('renting_insurcance:BEFORE_1945')}</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        <label>{t('renting_insurcance:QUESTION_ELAVATOR_PRESENT')}</label>
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Control as="select" defaultValue="0" onChange={(e) => setElevatorPresent(e.target.value)} >
                            <option value="false">{t('renting_insurcance:NO')}</option>
                            <option value="true">{t('renting_insurcance:YES')}</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        <label>{t('renting_insurcance:QUESTION_ADDICT')}</label>
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Control as="select" defaultValue="0" onChange={(e) => setAddic(e.target.value)} >
                            <option value="false">{t('renting_insurcance:NO')}</option>
                            <option value="true">{t('renting_insurcance:YES')}</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        <label>{t('renting_insurcance:QUESTION_PARKINGS')}</label>
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Control as="select" defaultValue="0" onChange={(e) => setAddiotionalSpace(e.target.value)} >
                            <option value="false">{t('renting_insurcance:NO')}</option>
                            <option value="true">{t('renting_insurcance:YES')}</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 6 }} xs={11} >
                        <label>{t('renting_insurcance:FAMILY_COMPOSITION')}</label>
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Control as="select" defaultValue="1" onChange={(e) => legalInsuranceChange(e.target.value)} disabled={makingCalcultion}>
                            <option value="1">{t('renting_insurcance:LEGAL_SINGLE')}</option>
                            <option value="2">{t('renting_insurcance:LEGAL_FAMILY')}</option>
                            <option value="3">{t('renting_insurcance:LEGAL_FAMILY_WITH_CHILDREN')}</option>
                            <option value="4">{t('renting_insurcance:LEGAL_FAMILY_OLDER_60')}</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col md={{ offset: 2, span: 8 }} xs={11} className="options_message">
                        {t('renting_insurcance:CONTACT_US_OPTIONS_PART_1')}<a href="/user_question"> {t('renting_insurcance:CONTACT_US_OPTIONS_PART_2')}</a>
                    </Col>
                </Row>
                {!surfaceToBig ?
                    <div>
                        <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                            <img src="/loader.svg" alt="" />
                        </div>
                        <div hidden={!calcaultionMade}>
                            <CalculationSummary
                                calulationResult={calulationResult} ></CalculationSummary>
                        </div>
                    </div> : null}
                {surfaceToBig === true ? <ErrorMessage message={t('renting_insurcance:UNABLE_TO_PROVIDE_CONTRACT_MESSAGE')} /> : null}
                <Row>
                    <Col md={{ span: 12 }} xs={11}>
                        <hr></hr>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                    <Col md={{ span: 6 }} xs={11} >
                        <Button className="btn btn-success text-white CDA_button_green CDA_button" onClick={() => { setStepper(1); }} >{t('general:FORM_BUTTON_BACK')}</Button>
                    </Col>
                    <Col xs={6} md={6}>
                        <Button disabled={!calcaultionMade || surfaceToBig} className="btn btn-success CDA_button_green CDA_button text-white" onClick={() => { setStepper(2); }}>{t('renting_insurcance:FORM_STEPPER_BUTTON_VRAGEN')} </Button>
                    </Col>
                </Row>
            </div>
            <div hidden={!showStep_2}>
                <InsuranceQuestions
                    handleStepper={setStepper}
                    legalInsuranceAssistanceToPeople={legalInsuranceAssistanceToPeople}
                    legalInsuranceCivilLiability={legalInsuranceCivilLiability}
                    calcaultionMade={calcaultionMade}
                    responseContractSuspended={responseContractSuspended}
                    setResponseLiabilityQuestion={setResponseLiabilityQuestion}
                    responseLiabilityQuestion={responseLiabilityQuestion}
                    calulationResult={calulationResult}
                    setResponseContractSuspended={setResponseContractSuspended} />
            </div>
            <div hidden={!showStep_3} className="caculation_component">
                <InsuranceOptions
                    handleStepper={setStepper}
                    calcaultionMade={calcaultionMade}
                    responseContractSuspended={responseContractSuspended}
                    responseLiabilityQuestion={responseLiabilityQuestion}
                    calulationResult={calulationResult}
                    determinelegalInsuranceAssistanceToPeople={determinelegalInsuranceAssistanceToPeople}
                    determineLegalInsuranceCivilLiability={determineLegalInsuranceCivilLiability} />
            </div>
            <div hidden={!showStep_4} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row style={{ marginBottom: 20 }} >
                        <Col md={{ offset: 2, span: 4 }} xs={11} >
                            <Form.Label>{t('FORM_FIRST_NAME')}</Form.Label><span className="error_span" />
                            <Form.Control type="text" placeholder="" ref={register} name="firstname" id="firstname" />
                            {errors.firstname && <Alert className="myAlert" variant="danger">{errors.firstname?.message}</Alert>}
                        </Col>
                        <Col md={{ span: 4 }} xs={11} >
                            <Form.Label>{t('FORM_LAST_NAME')}</Form.Label><span className="error_span" />
                            <Form.Control type="text"
                                placeholder=""
                                name="name"
                                id="name"
                                ref={register}
                            />
                            {errors.name && <Alert className="myAlert" variant="danger">{errors.name?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={{ offset: 2, span: 3 }} xs={11}>
                            <Form.Label>{t('FORM_EMAIL')}</Form.Label><span className="error_span" />
                            <Form.Control type="text" name="email" id="email" placeholder="" ref={register} />
                            {errors.email && <Alert className="myAlert" variant="danger">{errors.email?.message}</Alert>}
                        </Col>
                        <Col md={{ span: 2 }} xs={11}>
                            <Form.Label>{t('FORM_PHONE')}</Form.Label>
                            <Form.Control type="text" placeholder="" name="phone" id="phone" ref={register} />
                            {errors.phone && <Alert className="myAlert" variant="danger">{errors.phone?.message}</Alert>}
                        </Col>
                        <Col md={{ span: 3 }} xs={11}>
                            <Form.Label>{t('FORM_DATE_OF_BIRTH')}</Form.Label>
                            <DatePicker
                                selected={dateOfBirth}
                                required
                                value={dateOfBirth}
                                onChange={date => setDateOfBirth(date)}
                                className="form-control"
                                name="dateofbirth"
                                id="dateofbirth"
                                ref={register}
                                dateFormat="dd/MM/yyyy" />
                            {errors.dateofbirth && <Alert className="myAlert" variant="danger">{errors.dateofbirth?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col xs={11} md={{ offset: 2, span: 6 }} >
                            <Form.Label>{t('FORM_STREET')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="street"
                                id="street"
                                placeholder=""

                                ref={register} />
                            {errors.street && <Alert className="myAlert" variant="danger">{errors.street?.message}</Alert>}
                        </Col>
                        <Col xs={11} md={{ span: 2 }} >
                            <Form.Label>{t('FORM_NUMBER')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="number"
                                id="number"
                                placeholder=""
                                ref={register} />
                            {errors.number && <Alert className="myAlert" variant="danger">{errors.number?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={{ offset: 2, span: 6 }} xs={11} >
                            <Form.Label>{t('FORM_CITY')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="city"
                                id="city"
                                placeholder=""
                                ref={register} />
                            {errors.city && <Alert className="myAlert" variant="danger">{errors.city?.message}</Alert>}
                        </Col>
                        <Col md={2} xs={11} >
                            <Form.Label>{t('FORM_ZIP')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="zip"
                                id="zip"
                                placeholder=""
                                ref={register} />
                            {errors.zip && <Alert className="myAlert" variant="danger">{errors.zip?.message}</Alert>}
                        </Col>
                    </Row>
                    {/* <Row style={{ marginBottom: 10 }}>
                        <Col md={{ offset: 2, span: 8 }} xs={11}>
                            <Form.Check inline aria-label="option 1" ref={register}
                                id="agreement"
                                name="agreement" />
                            <PrivacyCondition />
                            {errors.agreement && <Alert className="myAlert" variant="danger">{errors.agreement?.message}</Alert>}
                        </Col>
                    </Row> */}
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={{ offset: 2, span: 8 }} xs={11}>
                            <Form.Check inline checked={otherRiskAddress} ref={register} id="riskAddress" name="riskAddress" onChange={(e) => setOtherRiskAddress(e.target.checked)} /><label>{t('renting_insurcance:RISK_ADDRESS_IS_DIFFERENT')}</label>
                        </Col>
                    </Row>
                    {otherRiskAddress ? <InsuranceRiskAddress errors={errors} register={register} /> : null}
                    {/* <Row> 
                        <Col md={{ offset: 2 }} xs={11}>
                            <Form.Label><span className="error_span" /> {t('FORM_REQUIRED_FIELD')}</Form.Label>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col md={{ span: 12 }} xs={11}>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col md={{ span: 6 }} xs={11} >
                            <Button className="btn btn-success text-white CDA_button_green CDA_button" onClick={() => { setStepper(3); }} >{t('renting_insurcance:FORM_STEPPER_OPTIONS')}</Button>
                        </Col>
                        <Col xs={6} md={6}>
                            <Button type="submit" className="CDA_button CDA_button_green" variant="success" >{t('renting_insurcance:FORM_STEPPER_POLIS_INORMATION')}</Button>
                        </Col>
                    </Row>
                </form>
            </div>
            <div hidden={!showStep_5}>
                <InsuranceActive handleStepper={setStepper}
                    alreadyInsurred={alreadyInsurred} setAlreadyInsurred={setAlreadyInsurred}
                    setCurrentInsuranceCompany={setCurrentInsuranceCompany}
                    register={register}
                    polisStartDate={polisStartDate}
                    setPolisStartDate={setPolisStartDate} />
            </div>
            <div hidden={!showStep_6}>
                <Row style={{ marginBottom: 20 }} className="caculation_component">
                    <Col md={{ offset: 1, span: 10 }} xs={11}>
                        <CalculationSummaryPaymentSelection
                            calulationResult={calulationResult}
                            handler={requestPayment}
                            legalInsuranceAssistanceToPeople={legalInsuranceAssistanceToPeople}
                            legalInsuranceCivilLiability={legalInsuranceCivilLiability}>
                        </CalculationSummaryPaymentSelection>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ offset: 1, span: 10 }} xs={11}>
                        <hr></hr>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                    <Col xs={6} md={6}>
                        <Button className="btn btn-success CDA_button_green CDA_button text-white" onClick={() => { setStepper(5); }}>{t('renting_insurcance:FORM_STEPPER_POLIS_INORMATION')} </Button>
                    </Col>
                </Row>
            </div>
        </div >
    )
}


