import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import MyGoogleReCaptcha from "../components/general/myGoogleReCaptcha";

export default function Footer() {

    const { t } = useTranslation();

    return (
        <div>

            <footer className="footer fixed-bottom" style={{ height: 350, position: "initial" }}>
                <div className="my_footer justify-content-md-center" style={{}}>
                    <Col style={{ top: 150 }}>
                        <ul className="footer_ul" >
                            <li><Link to="/terms_and_conditions">{t('FOOTER_TERMS_AND_CONDITIONS')}</Link></li>
                            <li> <span className="title__dots"> <span className="title_dot title__dots__dot--green"></span></span></li>
                            <li><Link to="/privacy_conditions">{t('FOOTER_PRIVACY_CONDITIONS')}</Link></li>
                            <li> <span className="title__dots"> <span className="title_dot title__dots__dot--green"></span></span></li>
                            <li><Link to="/cookie_policy">{t('FOOTER_COOKIE_POLICY')}</Link></li>

                        </ul>
                        <ul className="footer_ul" >
                            <li>
                                {t('FOOTER_PART_1')}
                                <a href="https://cda.be/gdpr-privacy"
                                    title="privacybeleid">{t('FOOTER_PRIVACY_POLICY')}</a>
                                en de
                            <a href="https://policies.google.com/terms"
                                    title="Servicewoorwarden">{t('FOOTER_PRIVACY_SERVICE_CONDITIONS')}</a>
                                {t('FOOTER_PART_3')}
                            </li>
                        </ul>
                    </Col>
                </div >
            </footer>
        </div>
    )
}