import React, { useEffect } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from 'react-i18next';

const RentingStepper = (props) => {

    const { t } = useTranslation();

    useEffect(() => {

    }, [])




    return (
        <div>
            <Row>
                <Col md={{ offset: 1, span: 10 }} xs={11}>
                    <div className="bs-stepper">
                        <div className="bs-stepper-header" role="tablist">
                            <div className="step " data-target="#logins-part">
                                <button type="button" className={props.showStep_1 ? "stepper_active step-trigger" : "step-trigger"} role="tab" aria-controls="logins-part" id="logins-part-trigger">
                                    <span className={props.showStep_1 ? "bs-stepper-circle stepper_circle_active" : "bs-stepper-circle "}>1</span>
                                    <span className="bs-stepper-label">{t('renting_insurcance:FORM_STEPPER_CALCULATE')}</span>
                                </button>
                            </div><div className="line"></div>
                            <div className="step" data-target="#information-part">
                                <button type="button" className={props.showStep_2 ? "stepper_active step-trigger" : "step-trigger"} role="tab" aria-controls="information-part" id="information-part-trigger">
                                    <span className={props.showStep_2 ? "bs-stepper-circle stepper_circle_active" : "bs-stepper-circle "}>2</span>
                                    <span className="bs-stepper-label">{t('renting_insurcance:FORM_STEPPER_QUESTIONS')}</span>
                                </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#information-part">
                                <button type="button" className={props.showStep_3 ? "stepper_active step-trigger" : "step-trigger"} role="tab" aria-controls="information-part" id="information-part-trigger">
                                    <span className={props.showStep_3 ? "bs-stepper-circle stepper_circle_active" : "bs-stepper-circle "}>3</span>
                                    <span className="bs-stepper-label">{t('renting_insurcance:OPTIONS')}</span>
                                </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#information-part">
                                <button type="button" className={props.showStep_4 ? "stepper_active step-trigger" : "step-trigger"} role="tab" aria-controls="information-part" id="information-part-trigger">
                                    <span className={props.showStep_4 ? "bs-stepper-circle stepper_circle_active" : "bs-stepper-circle "}>4</span>
                                    <span className="bs-stepper-label">{t('renting_insurcance:FORM_STEPPER_INFORMATION')}</span>
                                </button>
                            </div>      <div className="line"></div>
                            <div className="step" data-target="#information-part">
                                <button type="button" className={props.showStep_5 ? "stepper_active step-trigger" : "step-trigger"} role="tab" aria-controls="information-part" id="information-part-trigger">
                                    <span className={props.showStep_5 ? "bs-stepper-circle stepper_circle_active" : "bs-stepper-circle "}>5</span>
                                    <span className="bs-stepper-label">{t('renting_insurcance:FORM_STEPPER_POLIS')}</span>
                                </button>
                            </div>
                            <div className="line"></div>
                            <div className="step" data-target="#information-part">
                                <button type="button" className={props.showStep_6 ? "stepper_active step-trigger" : "step-trigger"} role="tab" aria-controls="information-part" id="information-part-trigger">
                                    <span className={props.showStep_6 ? "bs-stepper-circle stepper_circle_active" : "bs-stepper-circle "}>6</span>
                                    <span className="bs-stepper-label">{t('renting_insurcance:FORM_STEPPER_PAY')}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 10 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
        </div>

    )
}

export default RentingStepper;