import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { useTranslation } from 'react-i18next';

export default function CookiePolicy() {
    const { t } = useTranslation();

    return (
        <div className="content">
            <Row className="justify-content-md-center" md={{ cols: 12 }}>
                <Col>
                    <h1 className="title">
                        {t('cookies:COOKIES_TITLE')}
                        <span className="title__dots">
                            <span className="title_dot title__dots__dot--green"></span>
                            <span className="title_dot title__dots__dot--blue"></span>
                            <span className="title_dot title__dots__dot--red"></span>
                        </span>
                    </h1>
                </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
                <Col xs={{ offset: 1 }}>
                    <span className="CDA_sub_title" >
                        {t('cookies:COOKIES_SUB_TITLE')}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1 }}>
                    <p>
                        {t('cookies:COOKIES_PAR_1')}
                    </p>
                </Col>
            </Row>``
            <Row>
                <Col xs={{ offset: 1 }}>
                    <p>
                        {t('cookies:COOKIES_PAR_2')}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1 }}>
                    <p>  <strong>
                        {t('cookies:COOKIES_SUB_TITLE_COOKIES_KINDS')}
                    </strong>  </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1 }}>
                    <p>
                        {t('cookies:COOKIES_PAR_3')}
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1 }}>
                    <p>   <strong>
                        {t('cookies:COOKIES_SUB_TITLE_COOKIES_CONTROL')}
                    </strong>  </p>
                </Col>
            </Row>
            <Row>
                <Col xs={{ offset: 1 }}>
                    <p>
                        {t('cookies:COOKIES_PAR_4')}
                    </p>
                </Col>
            </Row></div>
    )
}