import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import CurrencyFormatter from '../general/CurrencyFormatter';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

const InsuranceOptions = (props) => {

    const { t } = useTranslation();

    useEffect(() => {
    }, [])

    return (
        <div>
            <Row>
                <Col md={{ offset: 1, span: 5 }} xs={11} >
                    <Form.Label>{t('renting_insurcance:OPTIONAL_COVERAGES')}</Form.Label>
                </Col>
                <Col md={{ span: 1 }} xs={11} >
                    <Form.Label>{t('renting_insurcance:Amount')} </Form.Label>
                </Col>
                <Col md={{ span: 1 }} xs={11} >
                    <Form.Label>{t('renting_insurcance:TAX')}</Form.Label>
                </Col>
                <Col md={{ span: 2 }} xs={11} style={{ textAlign: "right" }}>
                    <Form.Label> {t('renting_insurcance:TOTAL')}</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 10 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 5 }} xs={11} >
                    <Form.Check
                        inline
                        name="assistance_tot_people"
                        checked={props.legalInsuranceAssistanceToPeople}
                        onChange={props.determinelegalInsuranceAssistanceToPeople}
                        id="assistance_tot_people" />{t('renting_insurcance:OPTIONS_ASSISTANCE_TO_PEOPLE')}
                </Col>
                <Col md={{ span: 1 }} xs={11} >
                    <Form.Label><CurrencyFormatter data={props.calulationResult.assistanceToPeople} /> </Form.Label>
                </Col>
                <Col md={{ span: 1 }} xs={11} >
                    <Form.Label><CurrencyFormatter data={props.calulationResult.assistanceToPeopleTax} /></Form.Label>
                </Col>
                <Col md={{ span: 2 }} xs={11} style={{ textAlign: "right" }}>
                    <Form.Label> <CurrencyFormatter data={props.calulationResult.assistanceToPeopleTotal} /></Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 5 }} xs={11} >
                    <Form.Check inline name="civil_liability" id="civil_liability"
                        checked={props.legalInsuranceCivilLiability}
                        onChange={props.determineLegalInsuranceCivilLiability}
                    /> <Form.Label>{t('renting_insurcance:OPTIONS_CIVIL_LIABILITY')}</Form.Label>
                </Col>
                <Col md={{ span: 1 }} xs={11} >
                    <Form.Label><CurrencyFormatter data={props.calulationResult.civilLiability} /> </Form.Label>
                </Col>
                <Col md={{ span: 1 }} xs={11} >
                    <Form.Label><CurrencyFormatter data={props.calulationResult.civilLiabilityTax} /></Form.Label>
                </Col>
                <Col md={{ span: 2 }} xs={11} style={{ textAlign: "right" }}>
                    <Form.Label> <CurrencyFormatter data={props.calulationResult.civilLiabilityTotal} /></Form.Label>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 1, span: 10 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row hidden={props.responseContractSuspended || props.responseLiabilityQuestion} style={{ textAlign: "center" }}>
                <Col md={{ span: 6 }} xs={11} >
                    <Button className="btn btn-success text-white CDA_button_green CDA_button" onClick={() => { props.handleStepper(2); }} >{t('renting_insurcance:FORM_STEPPER_BUTTON_VRAGEN')}</Button>
                </Col>
                <Col xs={6} md={6}>
                    <Button disabled={!props.calcaultionMade} className="btn btn-success CDA_button_green CDA_button text-white" onClick={() => { props.handleStepper(4); }}>{t('renting_insurcance:FORM_STEPPER_BUTTON_INORMATION')} </Button>
                </Col>
            </Row>
        </div>
    )
}

export default InsuranceOptions

