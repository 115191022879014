import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import CurrencyFormatter from '../general/CurrencyFormatter';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { convertToBoolean } from '../general/GlobalFunctions';
import CalculationSummary from './calculationSummary';
import Error from '../error';
import ErrorMessage from './errorMessage';

const InsuranceQuestions = (props) => {

    const [alreadyInsurred, setAlreadyInsurred] = useState(false);


    const { t } = useTranslation();

    useEffect(() => {
    }, [])

    return (
        <div>
            <Row style={{ marginBottom: 20 }}>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <label>{t('renting_insurcance:QUESTIONS_CONTRACT_SUSPENDED')}</label>
                </Col>
                <Col xs={2} md={2}>
                    <Form.Control as="select" defaultValue="0" onChange={(e) => { props.setResponseContractSuspended(convertToBoolean(e.target.value)); }} >
                        <option value="false">{t('renting_insurcance:NO')}</option>
                        <option value="true">{t('renting_insurcance:YES')}</option>
                    </Form.Control>
                </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <label>{t('renting_insurcance:QUESTIONS_RESPONSIBILITY_CLAIM')}</label>
                </Col>
                <Col xs={2} md={2}>
                    <Form.Control as="select" defaultValue="0" onChange={(e) => { props.setResponseLiabilityQuestion(convertToBoolean(e.target.value)); }} >
                        <option value="false">{t('renting_insurcance:NO')}</option>
                        <option value="true">{t('renting_insurcance:YES')}</option>
                    </Form.Control>
                </Col>
            </Row>
            {props.responseContractSuspended || props.responseLiabilityQuestion ? <ErrorMessage message={t('renting_insurcance:UNABLE_TO_PROVIDE_CONTRACT_MESSAGE')} /> : null}
            <Row>
                <Col md={{ span: 12 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row hidden={props.responseContractSuspended || props.responseLiabilityQuestion} style={{ textAlign: "center" }}>
                <Col md={{ span: 6 }} xs={11} >
                    <Button className="btn btn-success text-white CDA_button_green CDA_button" onClick={() => { props.handleStepper(1); }} >{t('renting_insurcance:CALCULATE_INSURANCE')}</Button>
                </Col>
                <Col xs={6} md={6}>
                    <Button disabled={!props.calcaultionMade} className="btn btn-success CDA_button_green CDA_button text-white" onClick={() => { props.handleStepper(3); }}>{t('renting_insurcance:FORM_STEPPER_OPTIONS')} </Button>
                </Col>
            </Row>
        </div>
    )
}

export default InsuranceQuestions

