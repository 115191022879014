import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import CurrencyFormatter from '../general/CurrencyFormatter';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { convertToBoolean } from '../general/GlobalFunctions';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import moment from 'moment';
require('./../../../node_modules/react-datepicker/dist/react-datepicker.css')

const InsuranceActive = (props) => {

    const { t } = useTranslation();

    useEffect(() => {
    }, [])

    return (
        <div>
            <Row style={{ marginBottom: 20 }}>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <label>{t('renting_insurcance:START_DATE_POLIS')}</label>
                </Col>
                <Col xs={2} md={2}>
                    <DatePicker className="form-control" name="startDate" id="startDate"
                        ref={props.register} selected={props.polisStartDate}
                        onChange={date => props.setPolisStartDate(date)}
                        dateFormat="dd/MM/yyyy"
                        minDate={moment().add(1, 'days').toDate()} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <label>{t('renting_insurcance:QUESTION_ALREADY_INSURRED')}</label>
                </Col>
                <Col xs={2} md={2}>
                    <Form.Control as="select" defaultValue="0" onChange={(e) => { props.setAlreadyInsurred(convertToBoolean(e.target.value)); }} >
                        <option value="false">{t('renting_insurcance:NO')}</option>
                        <option value="true">{t('renting_insurcance:YES')}</option>
                    </Form.Control>
                </Col>
            </Row>
            {props.alreadyInsurred ? <Row style={{ marginBottom: 20 }}>
                <Col md={{ offset: 2, span: 6 }} xs={11} >
                    <label>{t('renting_insurcance:QUESTION_ALREADY_INSURRED_WHERE_INSURED')}</label>
                </Col>
                <Col xs={2} md={2}>
                    <Form.Control as="select" defaultValue="0" onChange={(e) => props.setCurrentInsuranceCompany(e.target.value)} >
                        <option value="1">AG</option>
                        <option value="2">KBC</option>
                        <option value="3">AXA</option>
                    </Form.Control>
                </Col>
            </Row> : null}
            <Row style={{ marginBottom: 20 }}>
                <Col md={{ offset: 2, span: 8 }} xs={11} >
                    <label>{t('renting_insurcance:QUESTION_ALREADY_INSURRED_ADDITIONAL_INFORMATION')}</label>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col md={{ offset: 2, span: 8 }} xs={11} >
                    <label>{t('renting_insurcance:QUESTION_ALREADY_INSURRED_ADDITIONAL_INFORMATION_TEXT_1')}</label><br />
                    <label>{t('renting_insurcance:QUESTION_ALREADY_INSURRED_ADDITIONAL_INFORMATION_TEXT_2')}</label><br />
                    <label>{t('renting_insurcance:QUESTION_ALREADY_INSURRED_ADDITIONAL_INFORMATION_TEXT_3')}</label><br />
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 12 }} xs={11}>
                    <hr></hr>
                </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
                <Col md={6} xs={11} >
                    <Button className="btn btn-success text-white CDA_button_green CDA_button" onClick={() => { props.handleStepper(4); }} >{t('renting_insurcance:FORM_STEPPER_BUTTON_INORMATION')}</Button>
                </Col>
                <Col xs={6} md={6}>
                    <Button className="btn btn-success CDA_button_green CDA_button text-white" onClick={() => { props.handleStepper(6); }}>{t('renting_insurcance:FORM_STEPPER_BUTTON_PAY')} </Button>
                </Col>
            </Row>
        </div>
    )
}

export default InsuranceActive

