import ApiConfig from '../../src/configuration/apiConfiguration';
const axios = require('axios');

const create = (phone, email, polis_number, relation_number, message, files) => {


    const data = new FormData();

    for (const file of files) {
        data.append('files', file, file.name);
    }

    var raw = JSON.stringify({
        "phone": phone,
        "email": email,
        "polisnumber": polis_number,
        "relationnumber": relation_number,
        "message": message
    });

    data.append('metadata', raw);

    var config = {
        data: data,
        method: 'post',
        url: ApiConfig.API_CONTROLLER_CLAIMS + ApiConfig.API_METHOD_POST_CLAIMS_CREATE,
        headers: {
            'Content-Type': 'application/json',
        }
    };

    return axios(config);
}


export default {
    create
};