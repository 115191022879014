import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { IUserInformation } from "../models/IUserInformation"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ICalendarService } from "../services/contracts/ICalendarService";
import CalendarServices from "../services/CalendarServices";
import { CalendarService } from "../services/CalendarService";
import { ITimeSlot } from './../models/ITimeSlot';
import { ITimeSlotBooking } from "../models/ITimeSlotBooking";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import '../../node_modules/font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { IAPIResult } from './../models/IAPIResult';
import PrivacyCondition from "./general/privacy_condition";
import WarningMessage from "./general/WarningMessage";
import MyGoogleReCaptcha from "./general/myGoogleReCaptcha";
import ErrorMessage from './general/ErrorMessage';

export default function Appointment() {
    const { t, i18n } = useTranslation();
    const [selectedDate, setSelectedDate] = useState();
    const [userInformation, setUserInformation] = useState<IUserInformation>({ zip: "0000", address: "" });
    const [showStep_1, setShowStep_1] = useState(true);
    const [showStep_2, setShowStep_2] = useState(false);
    const [showStep_3, setShowStep_3] = useState(false);
    const [showNoSlots, setShowNoSlots] = useState(false);
    const [showError, setShowError] = useState(false);
    const [zipCode, setZipCode] = useState<string>('');
    const [excludeDates, setExcludeDates] = useState<Date[]>();
    const [timeSlots, setTimeSlots] = useState<ITimeSlot[]>();
    const [filterredTimeSlots, setFilterredTimeSlots] = useState<ITimeSlot[]>();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>();
    const [activeIndex, setActiveIndex] = useState(-1);
    const [showSpinner, setShowSpinner] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
    const [comment, setComment] = useState();
    const history = useHistory();
    const { lang }: any = useParams()

    let myValidationSchema = yup.object().shape({
        firstname: yup.string().required(t('ERRORS_FIRST_NAME_REQUIRED')),
        name: yup.string().required(t('ERRORS_NAME_REQUIRED')),
        address: yup.string().required(t('ERRORS_ADRES_REQUIRED')),
        city: yup.string().required(t('ERRORS_CITY_REQUIRED')),
        zip: yup.string().matches(/^[0-9]{4}$/, t('ERRORS_ZIP_INCORRECT')),
        phone: yup.string().required(t('ERRORS_PHONE_REQUIRED')).matches(/^[0-9]{10}$/, t('ERRORS_PHONE_INVALID')),
        email: yup.string().required(t('ERRORS_EMAIL_REQUIRED')).email(t('ERRORS_EMAIL_VALID')),
        message: yup.string().required(t('ERRORS_MESSAGE_REQUIRED')),
        agreement: yup.boolean().oneOf([true], t('ERRORS_I_AGREE_TERMS_AND_CONDITIONS_REQUIRED')),
    });

    useEffect(() => {
        fetchTimeSlots();

        if (lang) {
            i18n.changeLanguage(lang.toLowerCase())
        }

    }, [selectedDate, showStep_2, zipCode])

    const handleSelectedTimeSlot = (index, id) => {
        setActiveIndex(index);
        setSelectedTimeSlot(id);
        setNextButtonDisabled(false);
    };

    const { register, handleSubmit, errors } = useForm({
        defaultValues: {
            firstname: "",
            name: "",
            address: "",
            city: "",
            zip: "",
            phone: "",
            email: "",
            message: "",
            agreement: false,
        },
        resolver: yupResolver(myValidationSchema),
    });

    const hideWarning = () => {
        setShowNoSlots(false);
        setShowStep_1(true);
    }

    const hideError = () => {
        setShowError(false);
        setShowStep_1(true);
    }

    async function fetchTimeSlots() {

        setNextButtonDisabled(true);
        setShowError(false);

        if (showStep_2 && userInformation != null && userInformation.zip !== "0000") {
            setShowSpinner(true);
            setShowNoSlots(false);
            const startDate = moment(selectedDate).format('YYYYMMDD');
            const endDate = moment(selectedDate).add(1, 'M').format('YYYYMMDD')

            CalendarServices.search(startDate, endDate, i18n.language, userInformation.zip).then(reponse => {
                let slots = Object.assign([], reponse.payload);
                setTimeSlots(slots);
                handleFilterredTimeSlots(startDate);
                setShowSpinner(false);

                if (slots.length === 0) {
                    setShowNoSlots(true);
                    setShowStep_2(false);
                }

            }).catch(error => {
                if (error.response === undefined || error.response.status === 401)
                    //  history.push("/error");
                    setShowSpinner(false);
                setShowError(true);

            });
        }
    }

    const handleFilterredTimeSlots = (date) => {
        const formattedDate = moment(date).format('YYYYMMDD');

        if (timeSlots !== undefined) {
            setFilterredTimeSlots(timeSlots.filter(t => t.date == formattedDate.toString()));
        }
    }

    const handleCalendarChange = (date) => {
        setSelectedDate(date);
    }

    const handleClosedDays = (e) => {
        setShowError(false);

        if (e.length === 4) {
            setZipCode(e);
            if (e.length === 4) {
                setShowSpinner(true);

                CalendarServices.getDays(moment(selectedDate).year(),
                    moment(selectedDate).month() + 1,
                    i18n.language, userInformation.zip).then(reponse => {
                        let tmpExcludeDates = Object.assign([], excludeDates);

                        console.log(reponse.data);

                        for (var val of reponse.data) {
                            const date = new Date(val.toString());
                            tmpExcludeDates.push(date);
                        }

                        setExcludeDates(tmpExcludeDates);
                        setShowSpinner(false);
                    }).catch(error => {
                        if (error.response === undefined || error.response.status === 401) {
                            //history.push("/error");
                            setShowSpinner(false);
                            setShowError(true);
                        }
                    });
            }
        }
    }

    const onSubmit = (data) => {
        let userInformation = {} as IUserInformation;
        userInformation.firstname = data["firstname"];
        userInformation.name = data["name"];
        userInformation.phone = data["phone"];
        userInformation.email = data["email"];
        userInformation.address = data["address"];
        userInformation.city = data["city"];
        userInformation.zip = data["zip"];
        userInformation.language = i18n.language;
        setUserInformation(userInformation);

        setComment(data["message"]);

        let timeSlotBooking = {} as ITimeSlotBooking;
        timeSlotBooking.userInformation = userInformation;
        timeSlotBooking.timeslotID = selectedTimeSlot;

        setShowStep_1(false);
        setShowStep_2(true);
        setShowStep_3(false);
    };

    const makeBooking = async () => {

        if (userInformation != null && userInformation.zip !== "0000") {
            setShowSpinner(true);
            let timeSlotBooking = {} as ITimeSlotBooking;
            timeSlotBooking.userInformation = userInformation;
            timeSlotBooking.timeslotID = selectedTimeSlot;
            timeSlotBooking.comment = comment

            const calendarService: ICalendarService = new CalendarService();
            const result: IAPIResult = await calendarService.makeBooking(timeSlotBooking);

            if (result.error != null) {
                setShowError(true);
            }
            else {
                setShowError(false);
            }

            setShowSpinner(false);
        }
    }

    return (
        <div className="content">
            <Row className="justify-content-md-center" style={{ padding: 20 }}>
                <div>
                    <span className="title" >
                        {t('HOME_I_WANT_AN_APPOINTMENT')}
                        <span className="title__dots">
                            <span className="title_dot title__dots__dot--green"></span>
                            <span className="title_dot title__dots__dot--blue"></span>
                            <span className="title_dot title__dots__dot--red"></span>
                        </span>
                    </span>
                </div>
            </Row>
            <div hidden={!showStep_1}>
                <Row className="justify-content-md-center">
                    <Col md={{ span: 6 }}>
                        <div className="bs-stepper">
                            <div className="bs-stepper-header" role="tablist">
                                <div className="step " data-target="#logins-part">
                                    <button type="button" className="step-trigger stepper_active" role="tab" aria-controls="logins-part" id="logins-part-trigger">
                                        <span className="bs-stepper-circle stepper_circle_active">1</span>
                                        <span className="bs-stepper-label">{t('FORM_STEPPER_INFORMATION')}</span>
                                    </button>
                                </div>
                                <div className="line"></div>
                                <div className="step" data-target="#information-part">
                                    <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                        <span className="bs-stepper-circle">2</span>
                                        <span className="bs-stepper-label">{t('FORM_STEPPER_TIMMING')}</span>
                                    </button>
                                </div>
                                <div className="line"></div>
                                <div className="step" data-target="#information-part">
                                    <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                        <span className="bs-stepper-circle">3</span>
                                        <span className="bs-stepper-label">{t('FORM_STEPPER_CONFIRM')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col xs={{ span: 11 }} sm={{ span: 3 }} >
                            <Form.Label>{t('FORM_FIRST_NAME')}</Form.Label><span className="error_span" />
                            <Form.Control type="text" placeholder="" ref={register} name="firstname"
                                id="firstname" />
                            {errors.firstname && <Alert className="myAlert" variant="danger">{errors.firstname?.message}</Alert>}
                        </Col>
                        <Col xs={{ span: 11 }} sm={{ span: 3 }} >
                            <Form.Label>{t('FORM_LAST_NAME')}</Form.Label><span className="error_span" />
                            <Form.Control type="text"
                                placeholder=""
                                name="name"
                                id="name"
                                ref={register}
                            />
                            {errors.name && <Alert className="myAlert" variant="danger">{errors.name?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col xs={{ span: 11 }} md={{ span: 6 }} >
                            <Form.Label>{t('FORM_ADRES')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="address"
                                id="address"
                                placeholder=""
                                ref={register} />
                            {errors.address && <Alert className="myAlert" variant="danger">{errors.address?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col md={{ span: 4 }} xs={{ span: 11 }} >
                            <Form.Label>{t('FORM_CITY')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="city"
                                id="city"
                                placeholder=""
                                ref={register} />
                            {errors.city && <Alert className="myAlert" variant="danger">{errors.city?.message}</Alert>}
                        </Col>
                        {/* </Row>
                    <Row style={{ marginBottom: 20 }}> */}
                        <Col md={2} xs={{ span: 11 }} >
                            <Form.Label>{t('FORM_ZIP')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="zip"
                                id="zip"
                                onChange={(e) => handleClosedDays(e.target.value)}
                                placeholder=""
                                ref={register} />
                            {errors.zip && <Alert className="myAlert" variant="danger">{errors.zip?.message}</Alert>}
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col md={{ span: 3 }} xs={{ span: 11 }}>
                            <Form.Label>{t('FORM_EMAIL')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                name="email"
                                id="email"
                                placeholder=""
                                ref={register} />
                            {errors.email && <Alert className="myAlert" variant="danger">{errors.email?.message}</Alert>}
                        </Col>
                        <Col md={{ span: 3 }} xs={{ span: 11 }}>
                            <Form.Label>{t('FORM_PHONE')}</Form.Label><span className="error_span" />
                            <Form.Control
                                type="text"
                                placeholder=""
                                name="phone"
                                id="phone"
                                ref={register} />
                            {errors.phone && <Alert className="myAlert" variant="danger">{errors.phone?.message}</Alert>}

                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col md={{ span: 6 }} xs={{ span: 11 }}>
                            <Form.Label>{t('FORM_APPOINTMENT_REASONS')}</Form.Label><span className="error_span" />
                            <Form.Control as="textarea"
                                rows={5}
                                name="message"
                                id="message"
                                ref={register} />
                            {errors.message && <Alert className="myAlert" variant="danger">{errors.message?.message}</Alert>}

                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col md={{ span: 6 }} xs={{ span: 11 }}>
                            <Form.Check inline aria-label="option 1" ref={register}
                                id="agreement"
                                name="agreement" />
                            <PrivacyCondition />
                            {errors.agreement && <Alert className="myAlert" variant="danger">{errors.agreement?.message}</Alert>}

                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                        <Col md={{ span: 6 }} xs={{ span: 11 }}>
                            <Form.Label><span className="error_span" /> {t('FORM_REQUIRED_FIELD')}</Form.Label>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginBottom: 50 }}>
                        <Col xs={{ span: 11 }} md={{ span: 2 }} >
                            <Link className="btn btn-success CDA_button CDA_button_green" to="/">{t('BACK_TO_HOME_SCREEN')}</Link>
                        </Col>
                        <Col xs={{ span: 11 }} md={{ span: 2 }} >
                            <Button type="submit" className="CDA_button CDA_button_green" variant="success">{t('SELECT_A_DATE')}</Button>
                        </Col>
                    </Row>
                </form>
            </div>
            <div hidden={!showStep_2}>
                <Row>
                    <Col md={{ offset: 3, span: 3 }} xs={11} >
                        <p> <b>{t('PICK_A_DATE')}</b> </p>
                        <DatePicker inline
                            dateFormat="dd/MM/yyyy"
                            selected={selectedDate}
                            excludeDates={excludeDates}
                            adjustDateOnChange
                            minDate={moment().toDate()}
                            onChange={date => handleCalendarChange(date)} />
                    </Col>
                    <Col md={{ span: 3, offset: 1 }} xs={11} >
                        <p> <b>{t('PICK_AN_HOUR')}</b>  </p>
                        <div hidden={showSpinner}>

                            {timeSlots?.length === 0 || timeSlots === undefined ? t('PICK_AN_HOUR_NO_VALUES') : ""}
                            <ul className="form-timeslots__list">
                                {filterredTimeSlots != null &&
                                    filterredTimeSlots.map((timeSlot: ITimeSlot, index) => (
                                        <li key={timeSlot.id} className="form-timeslots__list__item">
                                            <label
                                                id={timeSlot.id}
                                                onClick={() => handleSelectedTimeSlot(index, timeSlot.id)}
                                                className={activeIndex === index ? "form-timeslots__list__item_selected" : ""}>
                                                {timeSlot.startHour} - {timeSlot.endHour}
                                            </label></li>
                                    ))}
                            </ul>

                        </div>
                        <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                            <img src="/loader.svg" alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-md-center" style={{ marginBottom: 50, marginTop: 50 }}>
                    <Col xs={{ offset: 1, span: 11 }} md={{ span: 2 }} >
                        <a className="btn btn-success text-white CDA_button_green CDA_button"
                            onClick={() => { setShowStep_2(false); setShowStep_1(true); }} >{t('FORM_BUTTON_BACK_TO_DATA')}</a>
                    </Col>
                    <Col xs={{ offset: 1, span: 11 }} md={{ span: 2 }} >
                        <Button disabled={nextButtonDisabled} className="btn btn-success CDA_button_green CDA_button text-white" onClick={() => { setShowStep_2(false); setShowStep_3(true); makeBooking() }}>{t('FORM_BUTTON_CONFIRM')} </Button>
                    </Col>
                </Row></div>
            <div hidden={!showStep_3}>
                <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                    <img src="/loader.svg" alt="" />
                </div>
                <div hidden={showSpinner}>
                    <Row className="confirmation_booking_message" md={{ cols: 12 }}>
                        <Col >
                            <FontAwesomeIcon icon={faCheckCircle} size="3x" style={{ color: "#92BB4A" }} />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 20 }} className="confirmation_booking_message" md={{ cols: 12 }}>
                        <Col><p>{t('BOOKING_CONFIRMED')}</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div hidden={!showNoSlots}>
                <div hidden={!showSpinner} style={{ textAlign: "center" }}>
                    <img src="/loader.svg" alt="" />
                </div>
                <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                    <Col xs={{ span: 6 }} sm={{ span: 6 }}>
                        <div hidden={showSpinner}>
                            <WarningMessage message={t('NO_SLOTS_AVAILABLE')} click={hideWarning} />
                        </div>
                    </Col>
                </Row>
            </div >
            <div hidden={!showError}>
                <Row className="justify-content-md-center" style={{ marginBottom: 20 }}>
                    <Col xs={{ span: 6 }} sm={{ span: 6 }}>
                        <ErrorMessage click={hideError} />
                    </Col>
                </Row>
            </div >
            <MyGoogleReCaptcha />
        </div >
    )
}